import StatusTemplate from "./status-template";

const OwnerCanceledWithRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText=" تم إيقاف المزاد. سيتم إرجاع عربون المزاد إلى حسابك خلال 3 أيام"
      statusType="fail"
    />
  );
};

export default OwnerCanceledWithRefund;
