import { Flex, Image, Heading, Text } from "@chakra-ui/react";
import wrong from "assets/images/wrong.png";

const ServerError = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt="40px">
      <Image src={wrong} w="220px" m="auto" mb="20px" />
      <Text fontSize="28px" fontWeight="bold" mb="5px" textAlign="center">
        حدث خطأ ما
      </Text>
      <Text opacity="0.6">برجاء اعادة المحاولة فى وقت لاحق</Text>
    </Flex>
  );
};

export default ServerError;
