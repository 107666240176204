import { useState } from "react";
import {
  Box,
  Tr,
  Td,
  Text,
  Flex,
  Grid,
  useToast,
  Switch,
} from "@chakra-ui/react";
import {
  Table,
  CustomModal,
  Loading,
  CustomToast,
  CustomButton,
  Luhah,
  FlexContent,
} from "components/shared";
import { myListings, updateList } from "apis/lu7ah";
import ViewPopover from "./view-popover";
import { useQuery, useMutation } from "react-query";
import PopupDelete from "./popup-delete";
import AddPriceModal from "./add-priceModal";
import moment from "moment";
import { Link } from "react-router-dom";

const AuctionCard = ({
  id,
  serial,
  date,
  chars_ar,
  chars_en,
  numbers,
  status,
  type,

  refetch,
}) => {
  const toast = useToast();
  const [showAddPriceModal, setShowAddPriceModal] = useState(false);
  const [showDeleteLu7ahModal, setShowDeleteLu7ahModal] = useState(false);

  const Status = ({ statusText, statusType, ...props }) => {
    const fail = {
      bg: "#FBE7E3",
      color: "#E4715C",
    };

    const success = {
      bg: "#DCF1E2",
      color: "#0C9E38",
    };

    const pending = {
      bg: "#E8F3FE",
      color: "#278AF4",
    };
    const action = {
      bg: "#FFF4D2",
      color: "#F59629",
    };

    const statusTypes = (statusType) => {
      if (statusType == "pending") {
        return pending;
      } else if (statusType == "success") {
        return success;
      } else if (statusType == "action") {
        return action;
      }
      return fail;
    };

    const status = statusTypes(statusType);

    return (
      <Text
        p="10px"
        px="20px"
        borderRadius="30px"
        fontWeight="bold"
        {...status}>
        {statusText}
      </Text>
    );
  };

  const StatusTypes = ({ status }) => {
    if (status == "bidder_ended") {
      return <Status statusText="  مزاد منتهي" />;
    } else if (status == "bidder_canceled") {
      return <Status statusText="مزاد ملغى " />;
    } else if (status == "winner_canceled_with_refund") {
      return <Status statusText=" تم الغاء المزاد  " />;
    } else if (status == "winner_waiting_approval") {
      return (
        <Status statusType="pending" statusText="في إنتظار موافقة البائع" />
      );
    } else if (status == "winner_payment_proof_upload_requested") {
      return <Status statusType="action" statusText="الرجاء دفع قيمة المزاد" />;
    } else if (status == "winner_pending_payment_approval") {
      return <Status statusType="pending" statusText="المزاد تحت الإجراء" />;
    } else if (status == "winner_ended_without_refund") {
      return <Status statusText="تم الغاء المزاد  " />;
    } else if (status == "completed") {
      return <Status statusType="success" statusText=" مزاد منتهي" />;
    } else if (status == "owner_ended_without_bids_with_refund") {
      return <Status statusText="مزاد منتهي" />;
    } else if (status == "owner_waiting_approval") {
      return <Status statusType="pending" statusText="المزاد تحت الإجراء" />;
    } else if (status == "owner_canceled_with_refund") {
      return <Status statusText="مزاد ملغى" />;
    } else if (status == "owner_canceled_without_refund") {
      return <Status statusText=" مزاد ملغى" />;
    } else if (status == "owner_requested_to_accept_or_reject_highest_bid") {
      return (
        <Status statusType="action" statusText="الرجاء الموافقة على المزاد!" />
      );
    } else if (status == "owner_awaiting_payment_confirmation") {
      return <Status statusType="pending" statusText="في إنتظار تأكيد الدفع" />;
    } else if (
      status == "owner_ended_invalid_winner_proof_of_payment_with_refund"
    ) {
      return <Status statusText="تم الغاء المزاد  " />;
    } else if (status == "live") {
      return <Status statusType="success" statusText="مزاد قائم" />;
    }
  };

  return (
    <>
      <Box border="1px solid #e7e3e3" borderRadius="10px">
        <Box p="15px">
          <Flex justifyContent="space-between" mb="15px">
            <StatusTypes status={status} />
          </Flex>
          <Luhah
            charsAr={chars_ar}
            charsEn={chars_en}
            numbers={numbers}
            type={type}
          />
          <Box my="15px">
            <FlexContent label="رقم الاعلان" value={serial} />
            <FlexContent label="التاريخ" value={date} />
          </Box>
          <CustomButton
            name=" الدخول للمزاد  "
            as={Link}
            to={`/show-auctions/${id}`}
          />
        </Box>
      </Box>

      <CustomModal
        titleMarginBottom="20px"
        show={showDeleteLu7ahModal}
        setShow={setShowDeleteLu7ahModal}
        title="حذف اللوحة">
        <PopupDelete
          id={id}
          refetch={refetch}
          closePopup={setShowDeleteLu7ahModal}
        />
      </CustomModal>
    </>
  );
};

export default AuctionCard;
