import { useState, useRef } from "react";
import { Box, Text, Image, Flex } from "@chakra-ui/react";

const BankAccount = ({ accountNumber, iban, imgSrc }) => {
  const accountNumberRef = useRef();
  const ibanNumberRef = useRef();

  return (
    <Box bg="#f6f6f6" pos="relative" borderRadius="10px" p="20px">
      <Flex justifyContent="space-between" alignItems="flex-start">
        <Box>
          <Text fontWeight="bold" opacity="0.7">
            اسم الشركة
          </Text>
          <Text>شركة فلاة الحلول للخدمات التجارية</Text>
        </Box>

        <Image src={imgSrc} w="80px" />
      </Flex>

      <Box mb="15px" mt="15px">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap={["wrap", "noWrap", "nowrap", ""]}>
          <Box>
            <Text fontWeight="bold" opacity="0.7">
              رقم الحساب
            </Text>
            <Text
              fontSize="18px"
              color="#1073c0"
              ref={accountNumberRef}
              me="5px">
              {accountNumber}
            </Text>
          </Box>

          <Text
            bg="#1073c0"
            p="5px"
            px="15px"
            borderRadius="5px"
            color="white"
            cursor="pointer"
            w={["100%", "initial", "", ""]}
            textAlign="center"
            onClick={(e) => {
              navigator.clipboard.writeText(accountNumberRef.current.innerText);
            }}>
            نسخ
          </Text>
        </Flex>
      </Box>

      <Box>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexWrap={["wrap", "noWrap", "nowrap", ""]}>
          <Box>
            <Text fontWeight="bold" opacity="0.7">
              الايبان
            </Text>
            <Text fontSize="18px" color="#1073c0" ref={ibanNumberRef} me="5px">
              {iban}
            </Text>
          </Box>

          <Text
            bg="#1073c0"
            p="5px"
            px="15px"
            borderRadius="5px"
            color="white"
            cursor="pointer"
            w={["100%", "initial", "", ""]}
            textAlign="center"
            onClick={(e) => {
              navigator.clipboard.writeText(ibanNumberRef.current.innerText);
            }}>
            نسخ
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default BankAccount;
