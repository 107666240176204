import StatusTemplate from "./status-template";

const OwnerRequestedToAcceptOrRejectHighestBid = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="  مبروك، تم إنتهاء المزاد. الرجاء الموافقة أو رفض قيمة أعلى مزايدة."
      statusType="action"
    />
  );
};

export default OwnerRequestedToAcceptOrRejectHighestBid;
