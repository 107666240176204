import { useState, useRef } from "react";
import { Box, Flex, Image, Text, Grid, Input } from "@chakra-ui/react";
import { FaArrowLeft, FaLightbulb } from "react-icons/fa";
import { Container, CustomModal } from "components/shared";
import { Link } from "react-router-dom";
import { BankAccount, Modal } from "components/calculate-commission";
import rajhi from "assets/images/rajhi.svg";
import lu7ah from "assets/images/lu7ah.png";
import mada from "assets/images/mada.svg";
import masterCard from "assets/images/visa_master.png";
import amex from "assets/images/amex.svg";

const CalculateCommission = () => {
  const [price, setPrice] = useState("");
  const [show, setShow] = useState(false);

  const checkLength = (e) => {
    if (e.target.value.length === e.target.maxLength) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  };

  const sectionRef = useRef();

  const scroll = () => {
    sectionRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Box bg="rgb(242 ,244 ,250)" py="80px" mb="50px">
      <Container>
        <Flex
          alignItems="flex-end"
          flexWrap={["wrap", "", "nowrap", "nowrap"]}
          flexDirection={["column-reverse", "initial", "initial", ""]}>
          <Box w={["100%", "45%", "45%", "45%"]}>
            <Text fontWeight="bold" fontSize={["30px", "33px", "40px", "40px"]}>
              بيع لوحتك اليوم برسوم 1.5٪ فقط في منصة لوحة
            </Text>

            <Text w={["90%", "80%", "65%", "65%"]} my="20px">
              الرسوم أمانة في ذمة المعلن سواء تمت المبايعة عن طريق الموقع أو
              بسببه، وموضحة قيمتها بما يلي
            </Text>
            <Flex
              as={Link}
              onClick={scroll}
              p="15px"
              w="250px"
              alignItems="center"
              justifyContent="center"
              bg="bg.darkBlue"
              color="white"
              borderRadius="5px">
              <Text me="10px">حساب الرسوم</Text>
              <Box as={FaArrowLeft} />
            </Flex>
            {/* <Grid gridTemplateColumns="repeat(5,1fr)" mt="20px">
              <Image src={visa} />
            </Grid> */}
          </Box>

          <Image
            src={lu7ah}
            border="10px solid white"
            borderRadius="10px"
            w={["100%", "55%", "55%", "55%"]}
            mb={["20px", "0", "", ""]}
            h="400px"
            objectFit="contain"
          />
        </Flex>

        <Box bg="white" p="20px" borderRadius="10px" mt="80px" ref={sectionRef}>
          <Box w="100%" m="auto">
            <Grid
              gridTemplateColumns={[
                "repeat(1,1fr)",
                "repeat(2,1fr)",
                "repeat(2,1fr)",
                "repeat(2,1fr)",
              ]}
              gap="20px"
              mt="10px">
              <Box>
                <Text
                  fontSize="25px"
                  textAlign="center"
                  fontWeight="bold"
                  color="#1073c0"
                  mb="30px">
                  حساب الرسوم
                </Text>
                <Flex
                  px="5px"
                  py="2px"
                  border="1px solid rgb(212 ,218 ,222)"
                  borderRadius="5px"
                  alignItems="center"
                  flexWrap={["wrap", "nowrap", "nowrap", "nowrap"]}>
                  <Text
                    w={["100%", "40%", "40%", "40%"]}
                    borderLeft={[
                      "none",
                      "1px solid rgb(212 ,218 ,222)",
                      "",
                      "",
                    ]}
                    borderBottom={[
                      "1px solid rgb(212 ,218 ,222)",
                      "none",
                      "",
                      "",
                    ]}
                    textAlign="center"
                    py="10px">
                    ادخل سعر البيع
                  </Text>
                  <Input
                    type="number"
                    maxLength="13"
                    textAlign="center"
                    placeholder="1000"
                    border="none"
                    outline="none"
                    _active={{}}
                    _focus={{ boxShadow: "none" }}
                    onKeyDown={checkLength}
                    onChange={(e) => {
                      setPrice(e.target.value);
                    }}
                  />
                </Flex>

                <Flex
                  mt="15px"
                  bg="#f8faff"
                  px="5px"
                  py="5px"
                  borderRadius="5px"
                  alignItems="center"
                  position="relative"
                  flexWrap={["wrap", "nowrap", "nowrap", "nowrap"]}>
                  <Text
                    w={["100%", "40%", "40%", "40%"]}
                    borderLeft={[
                      "none",
                      "1px solid rgb(212 ,218 ,222)",
                      "",
                      "",
                    ]}
                    borderBottom={[
                      "1px solid rgb(212 ,218 ,222)",
                      "none",
                      "",
                      "",
                    ]}
                    textAlign="center"
                    py="30px"
                    fontWeight="bold"
                    color="#a5a3a3">
                    الرسوم المستحقة
                  </Text>
                  {parseFloat(price) < 1000 ? (
                    <Text
                      fontWeight="bold"
                      textAlign="center"
                      w={["100%", "60%", "60%", "60%"]}>
                      العمولة علينا{" "}
                    </Text>
                  ) : (
                    <Text
                      w={["100%", "60%", "60%", "60%"]}
                      textAlign="center"
                      py="10px">
                      <Text as="span" fontSize="30px" color="#1073c0">
                        {" "}
                        {parseFloat(price * 0.015).toFixed(2)}
                      </Text>{" "}
                      ريال
                    </Text>
                  )}

                  <Grid
                    w="30px"
                    h="30px"
                    borderRadius="50%"
                    bg="#d6dee6"
                    placeItems="center"
                    pos="absolute"
                    left="15px"
                    bottom="15px"
                    cursor="pointer"
                    onClick={() => setShow(true)}>
                    <Box as={FaLightbulb} color="#f8faff" />
                  </Grid>
                </Flex>
              </Box>
              <Box>
                <BankAccount
                  accountNumber="301000010006080300149"
                  iban="SA2280000301608010300149"
                  imgSrc={rajhi}
                />
                <Flex
                  as={Link}
                  to="/user"
                  border="1px solid #1073C0 "
                  px="10px"
                  borderRadius="10px"
                  m="auto"
                  mt="20px"
                  cursor="pointer"
                  alignItems="center"
                  justifyContent="space-between"
                  gridTemplateColumns="2fr 4fr"
                  flexWrap={["wrap", "nowrap", "nowrap", "nowrap"]}>
                  <Text
                    w={["100%", "40%", "40%", "40%"]}
                    fontWeight="bold"
                    color="#1073C0"
                    textAlign={["center", "initial", "initial", "initial"]}>
                    اضغظ هنا للدفع بـ
                  </Text>
                  <Flex
                    alignItems="center"
                    w={["100%", "60%", "60%", "60%"]}
                    justifyContent="center">
                    <Image src={mada} w="100px" />
                    <Image src={masterCard} w="100px" />
                    <Image src={amex} w="80px" />
                  </Flex>
                </Flex>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Container>
      <CustomModal show={show} setShow={setShow} maxW="500px">
        <Modal value={parseFloat(price * 0.015).toFixed(2)} price={price} />
      </CustomModal>
    </Box>
  );
};

export default CalculateCommission;
