// import {
//   Box,
//   Text,
//   Textarea,
//   InputGroup,
//   Input,
//   InputLeftAddon,
//   Flex,
//   useToast,
//   Spinner,
// } from "@chakra-ui/react";
// import {
//   Container,
//   CustomInput,
//   CustomButton,
//   ErrorMsg,
//   CustomToast,
// } from "components/shared";
// import { Formik, Form } from "formik";
// import { Link } from "react-router-dom";
// import { contactValidation } from "validations/contact-us";
// import { contact } from "apis/user";
// import { useMutation } from "react-query";

// const ContactUs = () => {
//   const toast = useToast();
//   const { mutate, isLoading } = useMutation("contact-us", contact, {
//     onSuccess: () => {
//       toast({
//         render: (props) => {
//           return (
//             <CustomToast
//               title={"شكرا لتواصلكم معنا"}
//               description="تم ارسال الرسالة بنجاح "
//               status="success"
//               onClose={props.onClose}
//             />
//           );
//         },
//       });
//     },
//     onError: () => {
//       toast({
//         render: (props) => {
//           return (
//             <CustomToast
//               title={"حدث خطأ"}
//               description="حدث خطأ ما برجاء اعادة المحاولة "
//               status="error"
//               onClose={props.onClose}
//             />
//           );
//         },
//       });
//     },
//   });

//   return (
//     <Box>
//       <Container>
//         <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="25px">
//           تواصل معنا
//         </Text>

//         <Formik
//           initialValues={{
//             name: "",
//             phone: "",
//             email: "",
//             message: "",
//             verifiyCode: "",
//           }}
//           validationSchema={contactValidation}
//           onSubmit={(values) => {
//             if (values.verifiyCode != 12) {
//               toast({
//                 render: (props) => {
//                   return (
//                     <CustomToast
//                       title={"قيمة الجمع"}
//                       description="يرجى ادخال قيمة الجمع بشكل صحيح "
//                       status="error"
//                       onClose={props.onClose}
//                     />
//                   );
//                 },
//               });
//             } else {
//               mutate({
//                 name: values.name,
//                 phone: values.phone,
//                 email: values.email,
//                 message: values.message,
//               });
//             }
//           }}
//         >
//           {(formik) => {
//             return (
//               <Form>
//                 <Box mb="25px">
//                   <CustomInput
//                     label="الاسم"
//                     value={formik.values.name}
//                     onChange={formik.handleChange("name")}
//                     onBlur={formik.handleBlur("name")}
//                   />
//                   {formik.errors.name && formik.touched.name && (
//                     <ErrorMsg text={formik.errors.name} />
//                   )}
//                 </Box>
//                 <Box mb="25px">
//                   <CustomInput
//                     label="رقم الجوال"
//                     value={formik.values.phone}
//                     onChange={formik.handleChange("phone")}
//                     onBlur={formik.handleBlur("phone")}
//                   />
//                   {formik.errors.phone && formik.touched.phone && (
//                     <ErrorMsg text={formik.errors.phone} />
//                   )}
//                 </Box>
//                 <Box mb="25px">
//                   <CustomInput
//                     label="الايميل"
//                     value={formik.values.email}
//                     onChange={formik.handleChange("email")}
//                     onBlur={formik.handleBlur("email")}
//                   />
//                   {formik.errors.email && formik.touched.email && (
//                     <ErrorMsg text={formik.errors.email} />
//                   )}
//                 </Box>

//                 <Box mb="25px">
//                   <Text textTransform="capitalize" mb="10px">
//                     نص الرسالة
//                   </Text>
//                   <Textarea
//                     h="200px"
//                     resize="none"
//                     _focus={{ border: "1px solid #a5c6d5", boxShadow: "none" }}
//                     value={formik.values.message}
//                     onChange={formik.handleChange("message")}
//                     onBlur={formik.handleBlur("message")}
//                   />
//                 </Box>

//                 <Box mb="25px">
//                   <Flex
//                     alignItems="center"
//                     border="1px solid #ced4da"
//                     borderRadius="5px"
//                     p="3px"
//                     h="40px"
//                   >
//                     <Text
//                       bg="red"
//                       color="white"
//                       w="100px"
//                       textAlign="center"
//                       letterSpacing="5px"
//                       py="5px"
//                     >
//                       6+6=
//                     </Text>
//                     <Input
//                       border="none"
//                       outline="none"
//                       ps="5px"
//                       _focus={{ border: "none", boxShadow: "none" }}
//                       value={formik.values.verifiyCode}
//                       onChange={formik.handleChange("verifiyCode")}
//                       onBlur={formik.handleBlur("verifiyCode")}
//                     />
//                   </Flex>
//                   {formik.errors.verifiyCode && formik.touched.verifiyCode && (
//                     <ErrorMsg text={formik.errors.verifiyCode} />
//                   )}
//                 </Box>
//                 <CustomButton
//                   name="ارسال"
//                   mt="50px"
//                   mb="60px"
//                   type="submit"
//                   display="flex"
//                   alignItems="center"
//                   disabled={isLoading}
//                   loading={isLoading && <Spinner me="5px" />}
//                 />
//               </Form>
//             );
//           }}
//         </Formik>
//       </Container>
//     </Box>
//   );
// };

// export default ContactUs;

import { useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Textarea,
  Button,
  useToast,
  Grid,
  Image,
  Spinner,
  Input,
} from "@chakra-ui/react";
import {
  CustomButton,
  CustomInput,
  Container,
  ErrorMsg,
  CustomToast,
} from "components/shared";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { contactValidation } from "validations/contact-us";
import { contact } from "apis/user";
import { useMutation } from "react-query";

import logo from "assets/images/logo.png";

const ContactUs = ({ ...props }) => {
  const toast = useToast();

  const { mutate, isLoading } = useMutation("contact-us", contact, {
    onSuccess: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"شكرا لتواصلكم معنا"}
              description="تم ارسال الرسالة بنجاح "
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: () => {
      toast({
        render: (props) => {
          return (
            <CustomToast
              title={"حدث خطأ"}
              description="حدث خطأ ما برجاء اعادة المحاولة "
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <>
      <Container>
        <Box
          bg="white"
          borderRadius="5px"
          w={["100%", "95%", "80%", ""]}
          m="auto"
          my="50px"
          boxShadow=" 9px 2px 20px -4px rgb(201, 201, 201) "
          {...props}>
          <Flex flexDir={["column", "row", "row", ""]}>
            <Grid
              p="50px"
              bg="#f3f3f3"
              w={["100%", "50%", "50%", ""]}
              borderRadius={["5px 5px 0 0", "0 5px 5px 0", "0 5px 5px 0", ""]}
              placeItems="center">
              <Box>
                <Image src={logo} w="150px" m="auto" mb="15px" />
                <Text
                  textAlign="center"
                  fontSize="25px"
                  fontWeight="bold"
                  color="black">
                  {" "}
                  منصة لوحة
                </Text>
                <Text textAlign="center" fontSize="30px" color="black">
                  منصتك للوح السيارات المميزة{" "}
                </Text>

                <Grid
                  gridTemplateColumns={[
                    "repeat(1,1fr)",
                    "repeat(2,1fr)",
                    "repeat(2,1fr)",
                    "",
                  ]}
                  m="auto"
                  mt="25px">
                  <Flex alignItems="center" justifyContent="center">
                    <Text>help@aerbag.app</Text>
                    <Box as={MdEmail} fontSize="25px" ms="5px" />
                  </Flex>
                  <Flex alignItems="center" justifyContent="center">
                    <Text>920034054</Text>
                    <Box as={IoLogoWhatsapp} fontSize="25px" ms="5px" />
                  </Flex>
                </Grid>
              </Box>
            </Grid>
            <Box p="50px" w={["100%", "50%", "50%", ""]}>
              <Text fontWeight="bold" mb="20px" fontSize="26px" color="#383838">
                كن على تواصل
              </Text>
              <Text mb="20px" color="#a2a2a2">
                فريقنا سيكون سعيد بالرد على استفسارك .املأ النموذج الموضح
                وسنتواصل معك ف اقرب وقت ممكن
                {/* Our team is happy to answer your questions. Fill out the form and we
            will be in touch as soon as possible. */}
              </Text>

              <Formik
                initialValues={{
                  name: "",
                  phone: "",
                  email: "",
                  message: "",
                  verifiyCode: "",
                }}
                validationSchema={contactValidation}
                onSubmit={(values) => {
                  if (values.verifiyCode != 12) {
                    toast({
                      render: (props) => {
                        return (
                          <CustomToast
                            title={"قيمة الجمع"}
                            description="يرجى ادخال قيمة الجمع بشكل صحيح "
                            status="error"
                            onClose={props.onClose}
                          />
                        );
                      },
                    });
                  } else {
                    mutate({
                      name: values.name,
                      phone: values.phone,
                      email: values.email,
                      message: values.message,
                    });
                  }
                }}>
                {(formik) => {
                  return (
                    <Form>
                      <Box mb="25px">
                        <CustomInput
                          placeholder="الاسم"
                          value={formik.values.name}
                          onChange={formik.handleChange("name")}
                          onBlur={formik.handleBlur("name")}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <ErrorMsg text={formik.errors.name} />
                        )}
                      </Box>
                      <Box mb="25px">
                        <CustomInput
                          placeholder="رقم الجوال"
                          value={formik.values.phone}
                          onChange={formik.handleChange("phone")}
                          onBlur={formik.handleBlur("phone")}
                        />
                        {formik.errors.phone && formik.touched.phone && (
                          <ErrorMsg text={formik.errors.phone} />
                        )}
                      </Box>
                      <Box mb="25px">
                        <CustomInput
                          placeholder="الايميل"
                          value={formik.values.email}
                          onChange={formik.handleChange("email")}
                          onBlur={formik.handleBlur("email")}
                        />
                        {formik.errors.email && formik.touched.email && (
                          <ErrorMsg text={formik.errors.email} />
                        )}
                      </Box>

                      <Box mb="25px">
                        <Flex
                          alignItems="center"
                          border="1px solid #ced4da"
                          borderRadius="5px"
                          p="3px"
                          h="40px">
                          <Text
                            bg="bg.darkBlue"
                            color="white"
                            w="100px"
                            textAlign="center"
                            letterSpacing="5px"
                            py="5px">
                            6+6=
                          </Text>
                          <Input
                            border="none"
                            outline="none"
                            ps="5px"
                            _focus={{ border: "none", boxShadow: "none" }}
                            value={formik.values.verifiyCode}
                            onChange={formik.handleChange("verifiyCode")}
                            onBlur={formik.handleBlur("verifiyCode")}
                          />
                        </Flex>
                        {formik.errors.verifiyCode &&
                          formik.touched.verifiyCode && (
                            <ErrorMsg text={formik.errors.verifiyCode} />
                          )}
                      </Box>

                      <Box mb="25px">
                        <Textarea
                          placeholder="الرسالة"
                          resize="none"
                          _active={{}}
                          _focus={{ boxShadow: "none" }}
                          value={formik.values.message}
                          onChange={formik.handleChange("message")}
                          onBlur={formik.handleBlur("message")}></Textarea>
                      </Box>

                      <CustomButton
                        name="ارسال"
                        mt="25px"
                        type="submit"
                        display="flex"
                        alignItems="center"
                        loading={isLoading}
                      />
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Flex>
        </Box>
      </Container>
    </>
  );
};

export default ContactUs;
