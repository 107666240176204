import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

const CustomModal = ({
  title,
  titleDir = "center",
  show,
  setShow,
  children,
  overflow = "inside",
  titleMarginBottom = "40px",
  ...props
}) => {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setShow(false)}
        isOpen={show}
        motionPreset="slideInBottom"
        scrollBehavior={overflow}
        zIndex="111111">
        <ModalOverlay />
        <ModalContent p="40px" pt="20px" px maxW="720px" mx="10px" {...props}>
          <ModalHeader
            textAlign={titleDir}
            fontSize="32px"
            p="0"
            color="text.600"
            mb={titleMarginBottom}>
            {title}
          </ModalHeader>
          <ModalCloseButton
            top="20px"
            right={titleDir == "right" ? "initial" : "20px"}
            left={titleDir == "right" ? "20px" : "initial"}
            fontSize="15px"
            fontWeight="bold"
            color="black"
            borderRadius="50%"
            p="20px"
            _focus={{}}
          />
          <ModalBody p="0">{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
