import StatusTemplate from "./status-template";

const OwnerAwatingPaymentConfirmation = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="طلبك تحت الإجراء. سيتم تحديث الإعلان في حال تم تحويل قيمة اللوحة من قبل
    العميل."
      statusType="pending"
    />
  );
};

export default OwnerAwatingPaymentConfirmation;
