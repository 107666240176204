import { useState, memo, useCallback, useContext, useEffect } from "react";
import { selectionsContext } from "contexts/selections";
import {
  Box,
  Text,
  Grid,
  Textarea,
  Flex,
  Checkbox,
  Link as ChakraLink,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import {
  Container,
  CustomSelect,
  CustomInput,
  CustomButton,
  ErrorMsg,
  CustomToast,
  Loading,
} from "components/shared";
import { useMutation, useQuery } from "react-query";
import { showList, updateList } from "apis/lu7ah";
import { useParams } from "react-router-dom";
// import {
//   typeSelection,
//   numberTypeSelections,
//   costType,
//   cities,
//   transportCost,
// } from "components/shared/selections";

import { LuhahInfo, PrivacyModal } from "components/add-adds";
import { Formik, Form, FieldArray } from "formik";
import { addAddValidation } from "validations/add-ads";

const EditAdd = memo(() => {
  const { type, number_type, cities, cost_transfer } =
    useContext(selectionsContext);

  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  const toast = useToast();

  const select = { name: "اختر", id: "" };

  //const [showPrivacy, setShowPrivacy] = useState(false);

  const [agreePrivacy, setAgreePrivacy] = useState(true);
  const [showPhone, setShowPhone] = useState(false);

  const [selectType, setSelectType] = useState(type[0]);
  const [selectNumberType, setSelectNumberType] = useState(
    number_type[number_type.length - 1]
  );
  const [selectCity, setSelectCity] = useState("");
  const [selectTransport, setSelectTransport] = useState(null);
  const [isPriceEnter, setIsPriceEnter] = useState(true);
  //select numbers
  const [selectNumberOne, setSelectNumberOne] = useState(null);
  const [selectNumberTwo, setSelectNumberTwo] = useState(null);
  const [selectNumberThree, setSelectNumberThree] = useState(null);
  const [selectNumberFour, setSelectNumberFour] = useState(null);

  const handleSelectType = (e, formik) => {
    setSelectType(e);
    formik.setFieldValue("type", e.id);
  };

  const handleSelectNumberType = useCallback((e, formik) => {
    formik.setFieldValue("numberType", e.id);

    if (e.id == "" || e.id == 4) {
      formik.setFieldValue("numbersList", ["", "", "", ""]);
    } else if (e.id == 1) {
      formik.setFieldValue("numbersList", [""]);
    } else if (e.id == 2) {
      formik.setFieldValue("numbersList", ["", ""]);
    } else if (e.id == 3) {
      formik.setFieldValue("numbersList", ["", "", ""]);
    }
    setSelectNumberOne(select);
    setSelectNumberTwo(select);
    setSelectNumberThree(select);
    setSelectNumberFour(select);
    setSelectNumberType(e);
  }, []);

  const handleSelectCity = (e, formik) => {
    setSelectCity(e);
    formik.setFieldValue("city", e.id);
  };

  const handleSelectTransport = (e, formik) => {
    setSelectTransport(e);
    formik.setFieldValue("cost", e.id);
  };

  // const handleSelectCostType = (e) => {
  //   setSelectCostType(e);
  // };

  const { id } = useParams();

  const { data, isLoading } = useQuery(["getListById", id], showList, {
    onSuccess: (e) => {
      const addData = e?.data?.data;

      setSelectType({ name: addData.type_text, id: addData.type });

      setSelectNumberType({
        name: addData.number_type_text,
        id: addData.number_type,
      });

      setSelectCity({ name: addData?.city_name, id: addData?.city });

      setSelectTransport({
        name: addData?.cost_transfer_text,
        id: addData?.cost_transfer,
      });

      setSelectNumberOne({
        name: addData?.num1,
        id: addData?.num1,
      });
      setSelectNumberTwo({
        name: addData?.num2,
        id: addData?.num2,
      });
      setSelectNumberThree({
        name: addData?.num3,
        id: addData?.num3,
      });
      setSelectNumberFour({
        name: addData?.num4,
        id: addData?.num4,
      });

      setIsPriceEnter(addData?.under_bidding == 1 ? false : true);
      setShowPhone(addData?.hidden_my_number == 1 ? false : true);
    },
  });

  const addData = data?.data?.data;

  const letter1 = { id: addData?.chars_en[0], name: addData?.chars_ar[0] };
  const letter2 = { id: addData?.chars_en[1], name: addData?.chars_ar[1] };
  const letter3 = { id: addData?.chars_en[2], name: addData?.chars_ar[2] };

  const { mutate, isLoading: loadingUpdate } = useMutation(
    "update-list",
    updateList,
    {
      onSuccess: () => {
        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"تعديل لوحة"}
                description="تم تعديل اللوحة بنجاح"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  if (isLoading) {
    return (
      <Grid placeItems="center" minH="60vh">
        <Loading />
      </Grid>
    );
  }
  return (
    <Box>
      <Container>
        <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="25px">
          تعديل الاعلان
        </Text>
        <Formik
          initialValues={{
            type: addData?.type,
            numberType: addData?.number_type,
            city: addData?.city,
            phone: addData?.mobile,
            price: addData?.price,
            cost: addData?.cost_transfer,
            notes: addData?.comment || "",
            letterOne: addData?.char1,
            letterTwo: addData?.char2,
            letterThree: addData?.char3,
            numbersList: addData?.numbers,
          }}
          validationSchema={addAddValidation}
          onSubmit={(values, formik) => {
            if (agreePrivacy) {
              if (
                values.letterOne == "" ||
                values.letterTwo == "" ||
                values.letterThree == ""
              ) {
                toast({
                  render: (props) => {
                    return (
                      <CustomToast
                        title="ادخال الحروف"
                        description="يجب ادخال جميع الحروف"
                        status="error"
                        onClose={props.onClose}
                      />
                    );
                  },
                });
              } else {
                if (isPriceEnter && values.price == "") {
                  toast({
                    render: (props) => {
                      return (
                        <CustomToast
                          title={"اضافة سعر"}
                          description="يجب ادخال سعر"
                          status="error"
                          onClose={props.onClose}
                        />
                      );
                    },
                  });
                } else {
                  mutate({
                    id,
                    data: {
                      type: values.type,
                      char1: values.letterOne,
                      char2: values.letterTwo,
                      char3: values.letterThree,
                      num1: values.numbersList[0].toString()[0],
                      num2: values.numbersList[1]
                        ? values.numbersList[1].toString()[0]
                        : "",
                      num3: values.numbersList[2]
                        ? values.numbersList[2].toString()[0]
                        : "",
                      num4: values.numbersList[3]
                        ? values.numbersList[3].toString()[0]
                        : "",
                      city: values.city,

                      price: values.price,
                      comment: values.notes,
                      cost_transfer: values.cost,
                      number_type: values.numberType,
                      under_bidding: !isPriceEnter,
                      hidden_my_number: showPhone ? 0 : 1,
                    },
                  });
                }
              }
            } else {
              toast({
                render: (props) => {
                  return (
                    <CustomToast
                      title="الموافقة على الشروط"
                      description="يجب الموافقة على الشروط"
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            }
          }}>
          {(formik) => {
            return (
              <Form>
                <FieldArray
                  name="numbersList"
                  render={(arrayHelpers) => (
                    <>
                      <Grid
                        gridTemplateColumns={[
                          "repeat(1,1fr)",
                          "repeat(2,1fr)",
                          "",
                          "",
                        ]}
                        gap="25px"
                        mb="25px">
                        <Box>
                          <CustomSelect
                            label="نوع اللوحة"
                            placeholder="اختر"
                            select={selectType}
                            handleSelect={(e) => handleSelectType(e, formik)}
                            selections={type}
                            colorLabel="black"
                          />
                          {formik.errors.type && (
                            <ErrorMsg text={formik.errors.type} />
                          )}
                        </Box>

                        <Box>
                          <CustomSelect
                            label="نوع الارقام"
                            placeholder="اختر"
                            select={selectNumberType}
                            handleSelect={(e) => {
                              handleSelectNumberType(e, formik);
                            }}
                            selections={number_type}
                            colorLabel="black"
                          />
                          {formik.errors.numberType && (
                            <ErrorMsg text={formik.errors.numberType} />
                          )}
                        </Box>
                      </Grid>
                      <LuhahInfo
                        selectNumberType={selectNumberType}
                        defaultLetter1={letter1}
                        defaultLetter2={letter2}
                        defaultLetter3={letter3}
                        defaultNumbers={addData?.numbers
                          .toString()
                          .replace(/,/g, "")}
                        formik={formik}
                        type={selectType}
                        selectNumberOne={selectNumberOne}
                        selectNumberTwo={selectNumberTwo}
                        selectNumberThree={selectNumberThree}
                        selectNumberFour={selectNumberFour}
                        setSelectNumberOne={setSelectNumberOne}
                        setSelectNumberTwo={setSelectNumberTwo}
                        setSelectNumberThree={setSelectNumberThree}
                        setSelectNumberFour={setSelectNumberFour}
                      />
                    </>
                  )}
                />

                <Box mb="25px">
                  <CustomSelect
                    label="المدينة"
                    placeholder="اختر مدينة"
                    select={selectCity}
                    handleSelect={(e) => {
                      handleSelectCity(e, formik);
                    }}
                    selections={cities}
                    colorLabel="black"
                  />
                  {formik.errors.city && <ErrorMsg text={formik.errors.city} />}
                </Box>
                {/* 
                <Box opacity="0.9">
                  <CustomInput
                    label="رقم الجوال"
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    onBlur={formik.handleBlur("phone")}
                    disabled
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMsg text={formik.errors.phone} />
                  )}
                </Box> */}

                <Text mb="5px" opacity={!isPriceEnter ? "0.4" : "1"}>
                  ادخل السعر
                </Text>
                <Flex
                  alignItems="flex-start"
                  mb="25px"
                  flexWrap={["wrap", "nowrap", "", ""]}>
                  <Box
                    w={["100%", "60%", "", ""]}
                    me={["0", "15px", "", ""]}
                    mb={["0", "0", "", ""]}>
                    <CustomInput
                      placeholder="بالريال السعودي"
                      type="number"
                      min="0"
                      opacity={!isPriceEnter ? "0.4" : "1"}
                      disabled={!isPriceEnter}
                      value={formik.values.price}
                      onChange={formik.handleChange("price")}
                      onBlur={formik.handleBlur("price")}
                    />
                    {formik.errors.price && formik.touched.price && (
                      <ErrorMsg text={formik.errors.price} />
                    )}
                  </Box>

                  <Flex alignItems="center" mt="6px">
                    <Checkbox
                      onChange={(e) => {
                        setIsPriceEnter(!isPriceEnter);
                        formik.setFieldValue("price", "");
                      }}
                      isChecked={!isPriceEnter}
                      me="10px"
                      _focus={{ boxShadow: "none" }}
                      _active={{}}
                    />
                    <Text>
                      السعر على{" "}
                      <Text as="span" color="#007bff">
                        السوم
                      </Text>
                    </Text>
                  </Flex>
                </Flex>

                <Box mb="25px">
                  <CustomSelect
                    label="تكلفة النقل"
                    placeholder="اختر"
                    select={selectTransport}
                    handleSelect={(e) => handleSelectTransport(e, formik)}
                    selections={cost_transfer}
                    colorLabel="black"
                  />
                  {formik.errors.cost && <ErrorMsg text={formik.errors.cost} />}
                </Box>

                <Box mb="25px">
                  <Text textTransform="capitalize" mb="10px">
                    ملاحظات
                  </Text>
                  <Textarea
                    h="200px"
                    resize="none"
                    _focus={{ border: "1px solid #a5c6d5", boxShadow: "none" }}
                    value={formik.values.notes}
                    onChange={formik.handleChange("notes")}
                    onBlur={formik.handleBlur("notes")}
                  />
                </Box>

                {/* <Flex alignItems="center">
                  <Checkbox
                    me="10px"
                    isChecked
                    onChange={() => setAgreePrivacy(!agreePrivacy)}
                    _focus={{ boxShadow: "none" }}
                    _active={{}}
                  />
                  <Text>
                    الموافقة على{" "}
                    <ChakraLink
                      href="https://www.policy.aerbag.com/"
                      target="_blank"
                      // onClick={() => setShowPrivacy(true)}
                      color="#007bff"
                      cursor="pointer"
                    >
                      شروط موقع لوحة
                    </ChakraLink>
                  </Text>
                </Flex> */}

                <Flex alignItems="center" mt="6px" mb="25px">
                  <Checkbox
                    onChange={(e) => {
                      setShowPhone(!showPhone);
                    }}
                    me="10px"
                    isChecked={showPhone}
                    _focus={{ boxShadow: "none" }}
                    _active={{}}
                  />
                  <Text>اظهار رقم الهاتف</Text>
                </Flex>

                {addData?.is_sold == 1 ? (
                  <Box
                    borderRadius="5px"
                    bg="#d1ecf1"
                    border="1px solid #bee5eb"
                    p="20px"
                    my="20px">
                    <Text textAlign="center">
                      لقد تم بيع هذه اللوحة لا يمكن التعديل عليها
                    </Text>
                  </Box>
                ) : (
                  <CustomButton
                    name="تعديل"
                    mt="50px"
                    mb="60px"
                    type="submit"
                    display="flex"
                    alignItems="center"
                    loading={loadingUpdate}
                  />
                )}

                {/* <PrivacyModal show={showPrivacy} setShow={setShowPrivacy} /> */}
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Box>
  );
});

export default EditAdd;
