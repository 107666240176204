import { useState, useEffect, useRef } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { IoIosArrowDown } from "react-icons/io";
import OutsideClickHandler from "react-outside-click-handler";

const CustomSelect = ({
  selections,
  placeholder,
  label,
  select,
  inputBg = "#f8f9fa",
  colorLabel = "white",
  handleSelect,
  maxMinueH = "400px",
  isBlank,
  isLabelBold,
  ...props
}) => {
  const [value, setValue] = useState(select || "");
  const [openMenu, setOpenMenu] = useState(false);

  const selectButton = useRef();

  const changeValue = (value) => {
    setValue(value);
    handleSelect(value);
  };

  useEffect(() => {
    setValue(select);
  }, [select]);

  const onClickOutSide = () => {
    setOpenMenu(false);
  };

  document.body.onkeydown = (e) => {
    if (e.key == "Escape") {
      onClickOutSide();
    }
  };

  return (
    <Box pos="relative">
      {isBlank ? null : (
        <Box
          mb="5px"
          fontWeight={isLabelBold ? "bold" : "normal"}
          color={colorLabel}
          fontSize={["14px", "16px", "", ""]}>
          {label}
        </Box>
      )}

      <OutsideClickHandler
        onOutsideClick={() => {
          onClickOutSide();
        }}>
        <Flex
          p="5px"
          px="12px"
          borderRadius="30px"
          alignItems="center"
          justifyContent="space-between"
          border="1px solid #E2E8F0"
          cursor="pointer"
          onClick={() => setOpenMenu(!openMenu)}
          ref={selectButton}
          bg={inputBg}
          {...props}>
          {isBlank ? null : (
            <>
              <Text>{value?.name || placeholder}</Text>
              <Box as={IoIosArrowDown} ms="10px" />
            </>
          )}
        </Flex>

        {openMenu && (
          <Box
            // transition="0.3s"
            bg="white"
            p="5px"
            borderRadius="5px"
            mt="5px"
            pos="absolute"
            w="100%"
            zIndex="11"
            boxShadow="0 1px 1px 1px #c3c3c3"
            maxH={maxMinueH}
            overflowY="auto">
            {selections?.map((e, i) => {
              return (
                <Box
                  key={i}
                  cursor="pointer"
                  p="5px"
                  transition="0.3s"
                  _hover={{
                    bg: e.id == value.id ? "#27ADF5" : "#f3f3f3",
                  }}
                  bg={e.id == value.id ? "#27ADF5" : "transparent"}
                  color={e.id == value.id ? "white" : "black"}
                  onClick={() => {
                    changeValue(e);
                    setOpenMenu(false);
                  }}>
                  {e.name}
                </Box>
              );
            })}
          </Box>
        )}
      </OutsideClickHandler>
    </Box>
  );
};

export default CustomSelect;
