import StatusTemplate from "./status-template";

const OwnerEndedWithoutBidsWithRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText=" تم إنتهاء المزاد بدون مزايدات. سيتم إرجاع عربون المزاد إلى حسابك خلال 3
    أيام"
      statusType="fail"
    />
  );
};

export default OwnerEndedWithoutBidsWithRefund;
