import StatusTemplate from "./status-template";

const OwnerEndedInvalidWinnerProofOfPaymentWithRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="تم رفض عملية الشراء وسيتم ارجاع قيمة المزاد الى حسابك "
      statusType="fail"
    />
  );
};

export default OwnerEndedInvalidWinnerProofOfPaymentWithRefund;
