import StatusTemplate from "./status-template";

const OwnerCanceledWithoutRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText=" تم إلغاء المزاد من قبل المُعلن. الرسوم المسددة غير مستردة"
      statusType="fail"
    />
  );
};

export default OwnerCanceledWithoutRefund;
