import axios from "axios";

export const configureAxios = (accessToken = null, language = null) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

  axios.defaults.headers.common["Accept-Language"] = language || "ar"; // Default to "ar" if no language is provided
  axios.defaults.headers.common["X-localization"] = language || "ar"; // Default to "ar" if no language is provided
  // axios.defaults.headers.common["X-platform"] = "web"; // Uncomment if needed

  if (accessToken) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken.replace(/^"(.*)"$/, "$1")}`;
  }

  axios.defaults.headers.post["Content-Type"] = "application/json";

  // axios.interceptors.response.use(
  //   (response) => response, // pass through successful responses
  //   (error) => {
  //     if (error.response && error.response.status === 401) {
  //       localStorage.removeItem("lu7ah_user");
  //       localStorage.removeItem("lu7ah_token");

  //       return Promise.reject(error);
  //     }
  //     return Promise.reject(error);
  //   }
  // );
};
