import { memo, useState, useEffect } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";

const CountdownTimer = memo(
  ({ startTime, endDate, typeStyle, timeStyle, ...props }) => {
    const [timeLeft, setTimeLeft] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });

    useEffect(() => {
      const calculateTimeLeft = () => {
        const now = new Date();
        const start = new Date(startTime);
        const end = new Date(endDate);

        const difference = end - now;

        if (difference <= 0) {
          setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
          return;
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        // Pad the values with leading zeros to ensure 2 digits
        setTimeLeft({
          days: String(days).padStart(2, "0"),
          hours: String(hours).padStart(2, "0"),
          minutes: String(minutes).padStart(2, "0"),
          seconds: String(seconds).padStart(2, "0"),
        });
      };

      const interval = setInterval(calculateTimeLeft, 1000);

      // Initial calculation
      calculateTimeLeft();

      // Cleanup the interval when component is unmounted
      return () => clearInterval(interval);
    }, [startTime, endDate]);

    const TimeBlock = memo(({ type, time }) => {
      return (
        <Box>
          <Text textAlign="center" fontWeight="bold" {...timeStyle}>
            {time}
          </Text>
          <Text textAlign="center" color="#a8a5a5" {...typeStyle}>
            {type}
          </Text>
        </Box>
      );
    });

    if (timeLeft?.days == "NaN") {
      return;
    }
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        gap="10px"
        mb="10px"
        {...props}>
        <TimeBlock type="ثوانى" time={timeLeft.seconds || "-"} />
        <TimeBlock type="دقائق" time={timeLeft.minutes || "-"} />
        <TimeBlock type="ساعات" time={timeLeft.hours || "-"} />
        <TimeBlock type="ايام" time={timeLeft.days || "-"} />
      </Flex>
    );
  }
);
export default CountdownTimer;
