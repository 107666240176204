import { Box, Text, Flex } from "@chakra-ui/react";
import ReactPaginate from "react-paginate";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { useTranslation } from "react-i18next";

const Prev = () => {
  return <Box as={HiArrowNarrowLeft} fontSize="20px" />;
};

const Next = () => {
  return <Box as={HiArrowNarrowRight} fontSize="20px" />;
};

const Pagination = ({
  handlePageClick,
  numberOfPages,
  itemsPerPage,
  currentPage,
  itemCount,
  totalItems,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {totalItems === 0 ? (
        ""
      ) : (
        <Flex justifyContent="space-between" alignItems="center">
          <Box dir="ltr">
            <ReactPaginate
              previousLabel={currentPage == 1 ? null : <Prev />}
              nextLabel={currentPage == numberOfPages ? null : <Next />}
              breakLabel={"..."}
              pageCount={numberOfPages} //count of pagination number
              marginPagesDisplayed={2} // count of number after final of breakpoints
              pageRangeDisplayed={3} // count of number between first and final breakpoints
              onPageChange={handlePageClick} // on click pagination number
              containerClassName={"pagination"} //class name for pagination parent
              pageClassName={"page-item"} //class name for number li
              pageLinkClassName={"page-link"} //class name for number link
              previousClassName={"navigate page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"navigate page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active_page"} //acitve number
              {...props}
            />
          </Box>

          <Text>
            {itemCount} من {totalItems}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default Pagination;
