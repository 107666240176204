import axios from "axios";

export const getAllListings = ({ pageParam = 1, queryKey }) => {
  const [
    _,
    char1,
    char2,
    char3,
    numberType,
    num1,
    num2,
    num3,
    num4,
    type,
    sort,
  ] = queryKey;

  return axios.get(
    `lu7ah-api/listings?page=${pageParam}&char1=${char1}&char2=${char2}&char3=${char3}&number_type=${numberType}&num1=${num1}&num2=${num2}&num3=${num3}&num4=${num4}&type=${type}&sort=${sort}`
  );
};

export const createList = (data) => {
  return axios.post("lu7ah-api/listings", data);
};

export const updateList = ({ id, data }) => {
  return axios.patch(`lu7ah-api/listings/${id}`, data);
};

export const showList = ({ queryKey }) => {
  return axios.get(`lu7ah-api/listings/${queryKey[1]}`);
};

export const deleteList = (id) => {
  return axios.delete(`lu7ah-api/listings/${id}`);
};

export const myListings = ({ queryKey }) => {
  const [_, pageNumber] = queryKey;

  return axios.get(`lu7ah-api/my-listings?page=${pageNumber}&sort=latest`);
};

export const getSelections = () => {
  return axios.get("lu7ah-api/listing-enum");
};

export const reportListing = ({ id, message }) => {
  return axios.post(`lu7ah-api/listings/${id}/report`, { message });
};
