import { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  Heading,
  Grid,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineUpload } from "react-icons/ai";
import { RiCloseFill } from "react-icons/ri";
import axios from "axios";
import ImageUploading from "react-images-uploading";
import FileNameSize from "./file-name-size";
import png from "assets/images/png.svg";
import jpg from "assets/images/jpg.svg";
import pdf from "assets/images/pdf.svg";
import xlsx from "assets/images/xlsx.svg";
import docx from "assets/images/docx.svg";
import { CustomButton } from "components/shared";
import { useMutation } from "react-query";
// import { createComment } from 'apis/tickets';
import { ErrorMsg } from "components/shared";
import { useTranslation } from "react-i18next";
import { uploadPaymentProof } from "apis/auctions";
import { FaFile } from "react-icons/fa";

const UploadFile = ({ auctionId, refetch, setShow }) => {
  const [images, setImages] = useState([]);
  const { t } = useTranslation();

  const maxNumber = 1;

  const { mutate, isLoading } = useMutation(uploadPaymentProof, {
    onSuccess: () => {
      refetch();
    },
  });

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const sendProof = () => {
    const fd = new FormData();
    fd.append("proof_of_payment", images[0].file);

    mutate({ auctionId, data: fd });
  };

  return (
    <Box>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        allowNonImageType
        acceptType={["jpg", "jpeg", "png", "pdf"]}>
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          errors,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <>
            {imageList.map((image, index) => (
              <Box p="15px" border="2px solid whitesmoke" borderRadius="10px">
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  dir="ltr">
                  <Flex alignItems="center">
                    <Box
                      as={FaFile}
                      fontSize="25px"
                      me="5px"
                      color="bg.darkBlue"
                    />
                    <Box>
                      <Text>{image.file.name}</Text>
                      <Text fontSize="15px" color="#A3AEB4">
                        {(image.file.size / 1000).toFixed(2) + " " + "kb"}
                      </Text>
                    </Box>
                  </Flex>

                  <Grid
                    w="30px"
                    h="30px"
                    borderRadius="50%"
                    bg="rgba(240,78,117,20%)"
                    placeItems="center"
                    fontSize="18px"
                    fontWeight="bold"
                    cursor="pointer"
                    onClick={() => onImageRemove(index)}>
                    <Box as={RiCloseFill} color="#F04E4E" />
                  </Grid>
                </Flex>
              </Box>
            ))}

            {imageList.length == 1 ? null : (
              <Flex
                py="10px"
                border="2px dashed #788596"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                flexDirection="column"
                bg="bg.2200"
                cursor="pointer"
                opacity={isDragging ? 0.5 : 1}
                onClick={onImageUpload}
                {...dragProps}>
                <Box as={AiOutlineUpload} fontSize="30px" />
                <Text>رفع صورة إيصال التحويل</Text>
              </Flex>
            )}

            {errors && (
              <Box>
                {errors.maxNumber && (
                  <ErrorMsg text="max number allowed to upload images is just one file" />
                )}
                {errors.acceptType && (
                  <span>Your selected file type is not allow</span>
                )}
                {errors.maxFileSize && (
                  <span>Selected file size exceed maxFileSize</span>
                )}
                {errors.resolution && (
                  <span>
                    Selected file is not match your desired resolution
                  </span>
                )}
              </Box>
            )}

            {imageList.length > 0 && (
              <CustomButton
                name="إرسال الإيصال"
                loading={isLoading}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="15px"
                h="50px"
                onClick={() => {
                  sendProof();
                }}
              />
            )}
          </>
        )}
      </ImageUploading>
    </Box>
  );
};

export default UploadFile;
