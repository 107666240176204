import { Box, Input, Text } from "@chakra-ui/react";

const CustomInput = ({
  type,
  label,
  textOpacity,
  placeholder,
  isLabelBold,
  ...props
}) => {
  return (
    <Box>
      {label && (
        <Text
          textTransform="capitalize"
          mb="10px"
          opacity={textOpacity}
          fontWeight={isLabelBold ? "bold" : "normal"}>
          {label}
        </Text>
      )}

      <Input
        type={type}
        placeholder={placeholder}
        w="100%"
        h="38px"
        border="1px solid #ced4da"
        borderRadius="5px"
        outline="none"
        _focus={{ border: "1px solid #a5c6d5", boxShadow: "none" }}
        {...props}
      />
    </Box>
  );
};

export default CustomInput;
