import StatusTemplate from "./status-template";

const WinnerWatingApproval = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="   مبروك، مزايدتك هي الأعلى. في إنتظار موافقة البائع على المزايدة."
      statusType="pending"
    />
  );
};

export default WinnerWatingApproval;
