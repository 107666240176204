import { useRef, useState, useEffect, useContext } from "react";
import {
  Box,
  Flex,
  Button,
  Text,
  Image,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Grid,
} from "@chakra-ui/react";

import { userContext } from "contexts/user";
import { Container, CustomButton } from "components/shared";
import { IoHome } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import { FaInfo, FaUserAlt, FaUser } from "react-icons/fa";
import { MdLocalPhone } from "react-icons/md";
import { GrMail } from "react-icons/gr";
import { BsFillHeartFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { CiLogout } from "react-icons/ci";
import { MdLogout } from "react-icons/md";

import { NavLink, Link } from "react-router-dom";
import { RiAuctionFill } from "react-icons/ri";
import lu7ahAuction from "assets/images/lu7ah-auction.svg";
import logo from "assets/images/logo.png";
import { MdAddCircle } from "react-icons/md";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));
  const { exit } = useContext(userContext);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.scrollY;
      setScrollPosition(position);
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let links = [
    { name: "الرئيسية", url: "/", icon: IoHome },
    { name: "مزاد ", url: "/auctions", img: lu7ahAuction, icon: RiAuctionFill },
    { name: "حساب العمولة", url: "/commission", icon: FaInfo },
    // { name: "تسجيل دخول", url: "/signin", icon: FaUserAlt },

    { name: "اتصل بنا", url: "/contact-us", icon: MdLocalPhone },
  ];

  const authenticatedLinks = [
    { name: "الرئيسية", url: "/", icon: IoHome },
    { name: "مزاد ", url: "/auctions", img: lu7ahAuction, icon: RiAuctionFill },
    { name: "حساب العمولة", url: "/commission", icon: FaInfo },

    { name: "اتصل بنا", url: "/contact-us", icon: MdLocalPhone },
  ];

  window.onresize = () => {
    if (window.innerWidth > 1020) {
      onClose();
    }
  };

  const [isFixed, setIsFixed] = useState(false);

  const handleScroll = () => {
    // Check if the scroll position is greater than 100px
    if (window.scrollY > 100) {
      setIsFixed(true); // Make the element fixed at the top
    } else {
      setIsFixed(false); // Reset to normal flow when not scrolled
    }
  };

  // Listen to the scroll event
  window.addEventListener("scroll", handleScroll);

  const LinkNav = ({ name, url, icon, img, isLastOne }) => {
    const [hover, setHover] = useState(false);

    return (
      <Flex
        alignItems="center"
        justifyContent="center"
        pos="relative"
        // me="30px"
        pe={isLastOne ? "0" : ["20px", "20px", "10px", "20px"]}
        minW="80px"
        _after={
          isLastOne
            ? null
            : {
                content: `""`,
                pos: "absolute",
                left: "0",
                w: "1px",
                h: "50%",
                bg: "#31459d2b",
              }
        }>
        <NavLink
          to={url}
          onMouseOver={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          <Flex alignItems="center">
            <Box as={icon} me="5px" color="text.darkBlue" />

            <Text color="text.blackColor">{name}</Text>
            {img ? <Image src={img} ms="5px" w="50px" /> : null}
          </Flex>
        </NavLink>

        <Box
          pos="absolute"
          h="3px"
          w={hover ? "25%" : "0"}
          transition=".3s"
          transform="translate(-50%,10px)"
          left="50%"
          bg="bg.darkBlue"
          bottom="0"
        />
      </Flex>
    );
  };

  return (
    <>
      <Box
        boxShadow="0 0 22px 0px #00385e"
        // {scrollPosition > 20 ? "0 0 22px 0px #00385e" : "none"}
        transition="0.3"
        left="0"
        right="0"
        top="0"
        zIndex="1111"
        py="18px"
        bg="#fbfbfb"
        pos="fixed">
        <Container>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box as={Link} to="/" _hover={{}} _active={{}} _focus={{}}>
                <Image
                  src={logo}
                  w="133px"
                  h="65px"
                  me={["45px", "", "20px", "45px"]}
                />
              </Box>
              <Box display={["none", "", "flex", ""]} gap="15px">
                {!user || !user?.is_profile_ready
                  ? links.map((e, i) => {
                      return (
                        <LinkNav
                          key={Math.random()}
                          name={e.name}
                          url={e.url}
                          icon={e.icon}
                          img={e?.img}
                          isLastOne={links?.length - 1 == i}
                        />
                      );
                    })
                  : authenticatedLinks.map((e, i) => {
                      return (
                        <LinkNav
                          key={Math.random()}
                          name={e.name}
                          url={e.url}
                          icon={e.icon}
                          img={e?.img}
                          isLastOne={authenticatedLinks?.length - 1 == i}
                        />
                      );
                    })}
              </Box>
            </Flex>
            <Flex
              gap="5px"
              alignItems="center"
              display={["none", "", "flex", ""]}>
              {!user || !user?.is_profile_ready ? (
                <Grid
                  display={["none", "", "flex", ""]}
                  as={Link}
                  to="/signin"
                  placeItems="center"
                  bg="#ececec"
                  borderRadius="10px"
                  p="14px"
                  cursor="pointer">
                  <Box as={FaUser} />
                </Grid>
              ) : (
                <>
                  <Grid
                    as={Link}
                    to="/favorites"
                    placeItems="center"
                    bg="bg.darkBlue"
                    borderRadius="10px"
                    p="14px"
                    cursor="pointer">
                    <Box as={BsFillHeartFill} color="white" />
                  </Grid>

                  <Menu>
                    <MenuButton
                      as={Button}
                      bg="none"
                      p="0"
                      _hover={{}}
                      _active={{}}
                      _focus={{}}>
                      <Grid
                        placeItems="center"
                        bg="bg.darkBlue"
                        borderRadius="10px"
                        p="14px"
                        cursor="pointer">
                        <Box as={FaUser} color="white" />
                      </Grid>
                    </MenuButton>

                    <MenuList zIndex="11111111111">
                      <MenuItem as={Link} to="/user" icon={<Box as={FaUser} />}>
                        الملف الشخصي
                      </MenuItem>
                      <MenuItem
                        color="red"
                        onClick={() => {
                          exit();
                          onClose();
                        }}
                        icon={<Box as={MdLogout} fontSize="22px" />}>
                        تسجيل الخروج
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </>
              )}

              <Menu>
                <MenuButton
                  as={Button}
                  bg="none"
                  p="0"
                  _hover={{}}
                  _active={{}}
                  _focus={{}}>
                  <Button
                    display={["none", "", "flex", ""]}
                    // to="add-ad"
                    color="white"
                    bg="bg.darkBlue"
                    px="50px"
                    py="23px"
                    fontWeight="normal"
                    borderRadius="13px"
                    _hover={{}}
                    _active={{}}
                    _focus={{}}>
                    <Box as={GoPlus} me="10px" />
                    <Text> إضافة إعلان </Text>
                  </Button>
                </MenuButton>

                <MenuList zIndex="11111111111">
                  <MenuItem
                    as={Link}
                    to="add-ad"
                    icon={
                      <Box
                        as={MdAddCircle}
                        color="text.darkBlue"
                        fontSize="22px"
                      />
                    }>
                    إعلان مجاني{" "}
                  </MenuItem>
                  <MenuItem
                    as={Link}
                    to="add-auction"
                    icon={
                      <Box
                        as={RiAuctionFill}
                        fontSize="22px"
                        color="text.darkBlue"
                      />
                    }>
                    مزاد{" "}
                    <Text as="span" fontWeight="bold">
                      LU
                      <Text as="span" fontWeight="bold" color="text.darkBlue">
                        7
                      </Text>
                      AH
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>

            <Box
              as={FiMenu}
              display={["block", "", "none", ""]}
              fontSize="35px"
              cursor="pointer"
              ref={btnRef}
              onClick={onOpen}
            />
          </Flex>
        </Container>
      </Box>

      {/* mobile drawer */}
      <Box display={["block", "", "none", ""]}>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          display={["block", "", "none", ""]}>
          <DrawerOverlay />
          <DrawerContent p="0">
            <DrawerBody p="0">
              <Box as={Link} to="/" _hover={{}} _active={{}} _focus={{}}>
                <Image
                  src={logo}
                  w="100%"
                  h="177px"
                  p="15px"
                  onClick={onClose}
                />
                <Box bg="#f9f9f9">
                  {!user || !user?.is_profile_ready ? (
                    links.map((e, i) => {
                      return (
                        <Flex
                          key={i}
                          as={Link}
                          to={e.url}
                          alignItems="center"
                          p="15px"
                          borderBottom="1px solid gainsboro"
                          onClick={onClose}>
                          <Box as={e.icon} me="5px" color="text.darkBlue" />
                          <Text color="text.blackColor">{e.name}</Text>
                          {e?.img ? (
                            <Image src={e?.img} ms="5px" w="50px" />
                          ) : null}
                        </Flex>
                      );
                    })
                  ) : (
                    <>
                      <Flex
                        as={Link}
                        to="/user"
                        alignItems="center"
                        p="15px"
                        borderBottom="1px solid gainsboro"
                        onClick={onClose}>
                        <Box as={FaUser} me="5px" color="text.darkBlue" />
                        <Text color="text.blackColor">الملف الشخصى</Text>
                      </Flex>

                      <Flex
                        as={Link}
                        to="/favorites"
                        alignItems="center"
                        p="15px"
                        borderBottom="1px solid gainsboro"
                        onClick={onClose}>
                        <Box
                          as={BsFillHeartFill}
                          me="5px"
                          color="text.darkBlue"
                        />
                        <Text color="text.blackColor">المفضلة</Text>
                      </Flex>
                      {authenticatedLinks.map((e, i) => {
                        return (
                          <Flex
                            key={i}
                            as={Link}
                            to={e.url}
                            alignItems="center"
                            p="15px"
                            borderBottom="1px solid gainsboro"
                            onClick={onClose}>
                            <Box as={e.icon} me="5px" color="text.darkBlue" />
                            <Text color="text.blackColor">{e.name}</Text>
                            {e?.img ? (
                              <Image src={e?.img} ms="5px" w="50px" />
                            ) : null}
                          </Flex>
                        );
                      })}
                    </>
                  )}

                  {user && user?.is_profile_ready && (
                    <Flex
                      alignItems="center"
                      p="15px"
                      borderBottom="1px solid gainsboro"
                      onClick={() => {
                        exit();
                        onClose();
                      }}>
                      <Text color="red">خروج</Text>
                    </Flex>
                  )}
                </Box>
                <Box m="15px">
                  <Menu>
                    <MenuButton
                      w="100%"
                      as={Button}
                      bg="none"
                      p="0"
                      _hover={{}}
                      _active={{}}
                      _focus={{}}>
                      <Button
                        as={Link}
                        to="add-adds"
                        color="white"
                        bg="bg.darkBlue"
                        px="50px"
                        py="23px"
                        w="100%"
                        fontWeight="normal"
                        borderRadius="13px"
                        _hover={{}}
                        _active={{}}
                        _focus={{}}>
                        <Box as={GoPlus} me="10px" />
                        إضافة إعلان{" "}
                      </Button>
                    </MenuButton>

                    <MenuList zIndex="11111111111" w="91%">
                      <MenuItem
                        as={Link}
                        to="add-ad"
                        onClick={onClose}
                        icon={
                          <Box
                            as={MdAddCircle}
                            color="text.darkBlue"
                            fontSize="22px"
                          />
                        }>
                        إعلان مجاني{" "}
                      </MenuItem>
                      <MenuItem
                        as={Link}
                        to="add-auction"
                        onClick={onClose}
                        icon={
                          <Box
                            as={RiAuctionFill}
                            fontSize="22px"
                            color="text.darkBlue"
                          />
                        }>
                        مزاد الكتروني
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Box>
              </Box>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default Header;
