import { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Text,
} from "@chakra-ui/react";
import { Container, CustomButton, CustomModal } from "components/shared";
import {
  Adds,
  EditInfo,
  EditPhone,
  AddPriceModal,
  MyBids,
} from "components/user";

const User = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Container>
      <Box
        border="1px solid rgba(0,0,0,.125)"
        borderRadius="5px"
        pb="20px"
        mb="50px">
        <Tabs variant="soft-rounded">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            py="10px"
            px="15px"
            bg="rgba(0,0,0,.03)"
            borderBottom="1px solid rgba(0,0,0,.125)"
            mb="20px"
            flexWrap={["wrap", "nowrap", "", ""]}>
            <TabList>
              <Tab
                px={["12px", "4", "", ""]}
                fontSize={["14px", "16px", "", ""]}>
                إعلاناتي
              </Tab>
              <Tab
                px={["12px", "4", "", ""]}
                fontSize={["14px", "16px", "", ""]}>
                مزاياداتي{" "}
              </Tab>
            </TabList>

            <Box>
              <CustomButton
                name="حساب العمولة"
                onClick={() => setShowModal(true)}
              />
            </Box>
          </Flex>

          <TabPanels px="15px">
            <TabPanel>
              <Adds />
            </TabPanel>
            <TabPanel>
              <MyBids />
            </TabPanel>
          </TabPanels>
        </Tabs>
        {/* <Box px="15px">
          <Adds />
        </Box> */}

        <CustomModal
          show={showModal}
          setShow={setShowModal}
          title="حساب العمولة">
          <AddPriceModal isShow />
        </CustomModal>
      </Box>
    </Container>
  );
};

export default User;
