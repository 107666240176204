import StatusTemplate from "./status-template";

const OwnerWaitingApproval = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="المزاد تحت الإجراء - سيتم إبلاغك خلال 24 ساعة"
      statusType="pending"
    />
  );
};

export default OwnerWaitingApproval;
