import { Flex, Grid, Text, Image, Box } from "@chakra-ui/react";
import lu7ahImage from "assets/images/LU7AH.svg";
import saudiLogo from "assets/images/saudi-logo.png";

const Luhah = ({ charsAr, charsEn, numbers, type, ...props }) => {
  const Letters = ({ lettersArr }) => {
    const checkLettersArr = lettersArr || ["-", "-", "-"];

    return (
      <>
        {checkLettersArr?.map((e) => {
          return (
            <Text fontWeight="bold" fontSize={["27px", "27px", "27px", ""]}>
              {e}{" "}
            </Text>
          );
        })}
      </>
    );
  };

  return (
    <Flex border="2px solid black" bg="#f9f9f9" borderRadius="5px" {...props}>
      <Box
        textAlign="center"
        p="5px"
        textTransform="uppercase"
        fontSize="14px"
        w="45px"
        bg={type == 1 ? "white" : "#27ADF5"}
        borderRadius="0 2px 2px 0">
        <Image src={saudiLogo} w="18px" h="18px" m="auto" mb="5px" />
        <Text fontSize={["20px", "20px", "20px", ""]} fontWeight="bold">
          k
        </Text>
        <Text fontSize={["20px", "20px", "20px", ""]} fontWeight="bold">
          s
        </Text>
        <Text fontSize={["20px", "20px", "20px", ""]} fontWeight="bold">
          a
        </Text>
        <Box w="8px" h="8px" borderRadius="50%" bg="black" m="auto" mt="2px" />
      </Box>

      <Flex pos="relative" w={["100%", "", "90%", ""]}>
        <Box borderInline="2px solid black" w="40%">
          <Flex
            borderBottom="2px solid black"
            h="50%"
            alignItems="center"
            justifyContent="center"
            gap="5px">
            <Letters lettersArr={charsAr} />
          </Flex>

          <Flex
            h="50%"
            alignItems="center"
            justifyContent="center"
            fontWeight="bold"
            gap="5px">
            <Letters lettersArr={charsEn} />
          </Flex>
        </Box>
        <Grid placeItems="center" w="60%">
          <Text
            fontWeight="bold"
            fontSize={["35px", "35px", "35px", ""]}
            letterSpacing="10px">
            {numbers}
          </Text>
        </Grid>
        <Grid pos="absolute" w="100%" h="100%" placeItems="center">
          <Image
            src={lu7ahImage}
            opacity="0.1"
            w={["210px", "", "210px", "230px"]}
          />
        </Grid>
      </Flex>
    </Flex>
  );
};

export default Luhah;
