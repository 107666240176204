import StatusTemplate from "./status-template";

const WinnerPaymentProofUploadRequested = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="
    تمت الموافقة على المزايدة. الرجاء تحويل المبلغ كامل للحساب البنكي
    المذكور في الصفحة ورفع صورة الإيصال"
      statusType="action"
    />
  );
};

export default WinnerPaymentProofUploadRequested;
