import { useState } from "react";
import { Box, Flex, Image, Text, Link as L, Heading } from "@chakra-ui/react";
import { Container } from "components/shared";
import { Link } from "react-router-dom";
import { BsTwitter, BsInstagram, BsPhone, BsWhatsapp } from "react-icons/bs";
// import { FaXTwitter } from "react-icons/fa6";
import logo from "assets/images/logo.png";
import googlePlay from "assets/images/andro.png";
import appleStore from "assets/images/apple.png";
import zatiqa from "assets/images/zatiqa.png";

const Footer = () => {
  const date = new Date();
  const currentYear = date.getFullYear();

  const FooterLink = ({ linkType, to, name, ...props }) => {
    const [hoverLink, setHoverLink] = useState(false);

    if (linkType == "innerLink") {
      return (
        <Box {...props}>
          <Box
            transition="0.3s"
            as={Link}
            to={to}
            color="#677294"
            lineHeight="26px"
            textDecor="none"
            textTransform="capitalize"
            _hover={{ color: "text.mainColor" }}
            onMouseOver={() => setHoverLink(true)}
            onMouseLeave={() => setHoverLink(false)}
            pos="relative">
            {name}
            <Box
              w={hoverLink ? "100%" : "0"}
              h="1px"
              bg="#1073c0"
              transition="0.3s"
              pos="absolute"
              bottom="-5px"
            />
          </Box>
        </Box>
      );
    }

    return (
      <Box {...props}>
        <Box
          transition="0.3s"
          as={L}
          href={to}
          target="_blank"
          textDecoration="none"
          color="#677294"
          lineHeight="26px"
          textDecor="none"
          textTransform="capitalize"
          _hover={{ color: "text.mainColor", textDecor: "none" }}
          onMouseOver={() => setHoverLink(true)}
          onMouseLeave={() => setHoverLink(false)}
          pos="relative">
          {name}
          <Box
            w={hoverLink ? "100%" : "0"}
            h="1px"
            bg="#1073c0"
            transition="0.3s"
            pos="absolute"
            bottom="-5px"
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box bg="#F2F4FA" borderTop="1px solid #d4dade" pt="80px">
      <Container>
        <Flex
          mb="50px"
          justifyContent="space-between"
          flexWrap={["wrap", "", "nowrap", ""]}
          px={["5%", "0", "", ""]}>
          <Box w={["100%", "50%", "30%", ""]}>
            <Image src={logo} w="180px" mb="40px" />
            <Text color="#677294" lineHeight="26px" mb="20px">
              موقع لوحة هي منصة الكترونية لعرض لوح السيارات في المملكة العربية
              السعودية - يتبع لتطبيق إيرباق - شركة فلاة الحلول للخدمات التجارية
            </Text>

            <Text mb="10px">
              البريد الإلكتروني:
              <Text as="span" color="#677294">
                {" "}
                help@aerbag.app
              </Text>
            </Text>

            <Text>
              الرقم الموحد:
              <Text as="span" color="#677294">
                {" "}
                920034054
              </Text>
            </Text>
            {/* <Image src={zatiqa} mt="20px" /> */}
          </Box>

          <Box w={["50%", "36%", "initial", ""]} mt={["40px", "0", "", ""]}>
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px">
              مركز المساعدة
            </Heading>
            <FooterLink
              linkType="innerLink"
              to="/terms"
              name="الشروط والأحكام"
              mb="20px"
            />
            <FooterLink
              linkType="innerLink"
              to="/privacy"
              name="سياسة الخصوصية"
              mb="20px"
            />
            <FooterLink
              linkType="innerLink"
              to="/about"
              name=" عن منصة لوحة"
              mb="20px"
            />
            {/* <FooterLink to="/career" name={t("footer.career")} mb="20px" /> */}
            {/* <FooterLink to="/help" name={t("footer.help")} /> */}
          </Box>

          {/* <Box mt={["40px", "", "0", ""]} w={["40%", "", "initial", ""]}>
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px">
              {t("footer.company")}
            </Heading>
            <FooterLink to="#" name={t("footer.about_us")} mb="20px" />
            <FooterLink to="/about-us" name={t("header.about")} mb="20px" />
            <FooterLink
              to="/solutions"
              name={t("footer.service_providers")}
              mb="20px"
            />
            <FooterLink to="#" name={t("footer.reporting")} mb="20px" />
            <FooterLink to="#" name={t("footer.work_tracking")} />
          </Box> */}

          <Box
            w={["100%", "36%", "initial", ""]}
            mt={["40px", "", "0", "0"]}
            display={["flex", "block", "", ""]}
            justifyContent="center">
            <Heading
              as="h3"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize="18px"
              mb="50px"
              display={["none", "block", "", ""]}>
              لتحميل التطبيق
            </Heading>
            <L
              href="https://play.google.com/store/apps/details?id=com.intcore.aerbag"
              target="_blank"
              display="block"
              mb="15px"
              me={["5px", "0", "", ""]}
              _hover={{}}>
              <Image src={googlePlay} borderRadius="5px" />
            </L>

            <L
              href="https://apps.apple.com/us/app/aerbag/id1532251378"
              target="_blank">
              <Image src={appleStore} borderRadius="5px" />
            </L>
          </Box>
        </Flex>
      </Container>
      <Box borderTop="1px solid #e0e3ef" py="20px">
        <Container>
          <Flex
            justifyContent="space-between"
            color="#677294"
            flexWrap={["wrap", "nowrap", "", ""]}>
            <Text
              w={["100%", "initial", "", ""]}
              textAlign={["center", "initial", "", ""]}
              mb={["15px", "0", "", ""]}>
              جميع الحقوق محفوظة لموقع لوحة {currentYear}
            </Text>

            <Flex
              alignItems="center"
              w={["100%", "initial", "", ""]}
              justifyContent={["center", "initial", "", ""]}
              mb={["15px", "0", "", ""]}>
              <L href="https://twitter.com/aerbagapp" target="_blank">
                <Box as={BsTwitter} />
              </L>
              <L
                mx="15px"
                href="https://instagram.com/aerbagapp"
                target="_blank">
                <Box as={BsInstagram} />
              </L>
              <L href="https://wa.me/966920034054" target="_blank">
                <Box as={BsWhatsapp} />
              </L>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  );
};

export default Footer;
