import * as Yup from "yup";

export const addAddValidation = Yup.object().shape({
  type: Yup.string().required("هذا الحقل مطلوب"),
  numberType: Yup.string().required("هذا الحقل مطلوب"),
  city: Yup.string().required("هذا الحقل مطلوب"),

  letterOne: Yup.string().required("هذا الحقل مطلوب"),

  letterTwo: Yup.string().required("هذا الحقل مطلوب"),
  letterThree: Yup.string().required("هذا الحقل مطلوب"),
  phone: Yup.string().required("هذا الحقل مطلوب"),

  cost: Yup.string().required("هذا الحقل مطلوب"),

  numbersList: Yup.array()
    .of(Yup.string().required("ادخل رقم"))
    .required("يجب كتابة الارقام كاملة"),
});
