import { Button, Box, Spinner } from "@chakra-ui/react";

const CustomButton = ({ isReject, name, icon, loading, ...props }) => {
  return (
    <Button
      //h="38px"
      minH="38px"
      maxH="100%"
      fontWeight="normal"
      bg={isReject ? "transparent" : "bg.darkBlue"}
      border={isReject ? "2px solid #f45a5a" : "none"}
      color={isReject ? "#f45a5a" : "white"}
      borderRadius="30px"
      w="100%"
      display="flex"
      alignItems="center"
      disabled={loading}
      _hover={{
        bg: isReject ? "#f45a5a" : "bg.darkBlue",
        color: "white",
      }}
      _focus={{ outline: "none" }}
      _active={{}}
      {...props}>
      {loading ? <Spinner me="5px" /> : null}
      {name}
      {icon ? <Box as={icon} ms="5px" fontSize="16px" /> : null}
    </Button>
  );
};

export default CustomButton;
