import { Box, Grid, Text, UnorderedList, Flex, Image } from "@chakra-ui/react";
import { CustomButton } from "components/shared";
import trust from "assets/images/trust.svg";
import { Link } from "react-router-dom";

const AuctionPolicyModal = ({ message, action, isLoading, setShow }) => {
  const ListItem = ({ item, ...props }) => {
    return (
      <Flex alignItems="center" {...props}>
        <Box w="8px" h="8px" borderRadius="50%" bg="bg.darkBlue" me="5px" />
        <Text>{item}</Text>
      </Flex>
    );
  };

  return (
    <Box>
      <Text mb="15px">
        <Text as="span" fontWeight="bold">
          {" "}
          منصة لوحة
        </Text>{" "}
        تتيح لك إنشاء مزاد الكتروني خاص بلوحة مركبتك، خالي من المتاعب وبكل
        سهولة.
      </Text>

      <Box mb="10px">
        <Text fontWeight="bold" my="15px">
          طريقة عمل المزاد:
        </Text>

        <ListItem item="إضافة معلومات اللوحة" />
        <ListItem item="دفع عربون المزاد 345 ريال سعودي" />
      </Box>

      <Box p="10px" mb="10px" borderRadius="10px" bg="#F4F4F4">
        <Flex alignItems="center" mb="10px">
          <Image src={trust} me="5px" />
          <Text fontWeight="bold">منصة لوحة تضمن حقك</Text>
        </Flex>
        <Text>
          <Text as="span" fontWeight="bold">
            {" "}
            تنويه:
          </Text>{" "}
          في حال تم إنتهاء المزاد بدون أي مزايدات، يتم إسترجاع العربون كامل
          لحسابك البنكي. لمعلومات أكثر عن المزاد،
          <Text
            as={Link}
            to="/terms"
            fontWeight="bold"
            color="text.darkBlue"
            cursor="pointer">
            {" "}
            أضغط هنا
          </Text>
        </Text>
      </Box>
      <ListItem item="إنتظار موافقة المنصة على الإعلان ونشره. تستغرق 24 ساعة." />

      <CustomButton
        name="تأكيد"
        borderRadius="30px"
        onClick={action}
        loading={isLoading}
        mt="20px"
      />
    </Box>
  );
};

export default AuctionPolicyModal;
