// 1. Import `extendTheme`
import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";

// aliases for breakpoints
const breakpoints = ["30rem", "48rem", "62rem", "80rem", "98rem"];
[breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl] = breakpoints;

const overRides = {
  ...chakraTheme,
  breakpoints,
  // custom fonts(fontFamily)
  fonts: {
    body: `Tajawal, sans-serif`,
    heading: `Tajawal, sans-serif`,
  },

  // custom colors
  colors: {
    text: {
      darkBlue: "#1D85F4",
      blackColor: "##242424",
    },
    bg: {
      darkBlue: "#1D85F4",
      lightBlue: "#007bff",
      success: "#C8E6C9",
      pending: "#FFF59D",
      error: "#fbe9e7",
    },
    // btn: {
    //   primary: '',
    //   primary_h: '',
    //   secondary: '',
    //   secondary_h: '',
    // },
  },

  // custom font size
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "4.5xl": "2.5rem", // 40px
    "5xl": "3rem", // 48px
    "6xl": "4rem", // 64px
    "7xl": "4.5rem", // 72px
    "8xl": "5rem", // 80px
    "9xl": "6rem", // 100px,
    "10xl": "7rem",
    "11xl": "8rem",
  },

  // custom margin and padding
  spacing: {
    xs: "8px",
    sm: "16px",
    md: "24px",
    lg: "32px",
  },

  // custom components
  components: {
    // customize chakra base components example
    Heading: {
      baseStyle: {
        fontWeight: "300",
      },
    },
  },
};

// 2. Call `extendTheme` and pass your custom values
const customTheme = extendTheme(overRides);
// export customTheme
export default customTheme;
