import { useState } from "react";
import { Box, Flex, Text, Grid, useToast } from "@chakra-ui/react";
import {
  Container,
  Luhah as L,
  Loading,
  CustomToast,
  CustomModal,
} from "components/shared";
import { TbExclamationMark } from "react-icons/tb";
import { TextCard, ReportModal } from "components/show-adds";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { showList } from "apis/lu7ah";
import { BsFillHeartFill } from "react-icons/bs";
import { useMutation } from "react-query";
import { addFavourite, removeFavourite } from "apis/faviourte";
import moment from "moment";

const ShowAd = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [showReportModal, setShowReportModal] = useState(false);

  const { id } = useParams();

  const { data, isLoading, refetch } = useQuery(["showList", id], showList);

  const user = JSON.parse(localStorage.getItem("lu7ah_user"));

  const { mutate: removeFav } = useMutation(
    "remove-favorite",
    removeFavourite,
    {
      onSuccess: (res) => {
        refetch();
        toast({
          render: (props) => {
            return (
              <CustomToast
                title="ازالة من المفضلة"
                description="تم ازالة اللوحة من المفضلة بنجاح"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: addFav } = useMutation("add-favorite", addFavourite, {
    onSuccess: (res) => {
      refetch();
      toast({
        render: (props) => {
          return (
            <CustomToast
              title="اضافة الى المفضلة"
              description="تم اضافة اللوحة الى المفضلة بنجاح"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const addData = data?.data?.data;

  if (isLoading) {
    return (
      <Grid placeItems="center" h="60vh">
        <Loading />
      </Grid>
    );
  }

  return (
    <Box pb="40px">
      <Container>
        <Box border="1px solid rgba(0,0,0,.125)" borderRadius="5px" pb="20px">
          <Flex
            justifyContent="space-between"
            p="15px"
            bg="rgba(0,0,0,.03)"
            borderBottom="1px solid rgba(0,0,0,.125)"
            mb="25px">
            <Text>
              رقم الإعلان{" "}
              <Text as="span" fontWeight="bold">
                {addData?.serial}
              </Text>
            </Text>

            <Box
              as={BsFillHeartFill}
              color={addData?.is_favorite ? "text.darkBlue" : "#aaa"}
              cursor="pointer"
              transition="0.3s"
              onClick={() => {
                if (!user || !user?.is_profile_ready) {
                  navigate("/signin");
                } else {
                  if (addData?.is_favorite) {
                    removeFav(id);
                    //mutate(id)
                  } else {
                    addFav(id);
                  }
                }
              }}
              _hover={{}}
            />
          </Flex>

          <Box px="20px">
            <Box mb="30px">
              <L
                charsEn={addData?.chars_en}
                charsAr={addData?.chars_ar}
                numbers={addData?.numbers}
                type={addData?.type}
              />
            </Box>

            <Grid
              gridTemplateColumns={["repeat(1,1fr)", "repeat(3,1fr)", "", ""]}
              mb="10px">
              <TextCard text="رقم الإعلان" value={addData?.serial} />
              <TextCard
                text="تاريخ النشر"
                value={moment(addData?.created_at).format("yyyy-MM-DD")}
              />
              <TextCard text="المدينة" value={addData?.city_name} />
            </Grid>

            <Grid
              gridTemplateColumns={["repeat(1,1fr)", "repeat(3,1fr)", "", ""]}
              mb="10px">
              <TextCard text="نوع اللوحة" value={addData?.type_text} />
              <TextCard
                text="تكلفة النقل"
                value={addData?.cost_transfer_text}
              />
              <TextCard
                text="السعر"
                value={
                  addData?.under_bidding == 1
                    ? " على السوم"
                    : `${addData?.price} ريال`
                }
              />
            </Grid>

            {(!user || !user?.is_profile_ready) && !addData?.is_expired && (
              <Box
                bg="white"
                p="10px"
                boxShadow="0 0 19px 0px #ccc"
                border="1px solid #c6c6c6">
                <Box
                  borderRadius="5px"
                  bg="#d1ecf1"
                  border="1px solid #bee5eb"
                  p="20px">
                  <Text textAlign="center">
                    يجب تسجيل الدخول لمراسلة المعلن
                  </Text>
                </Box>
              </Box>
            )}
          </Box>

          {user && user?.is_profile_ready && !addData?.is_expired && (
            <>
              <Grid
                gridTemplateColumns={["repeat(1,1fr)", "repeat(2,1fr)", "", ""]}
                mb="10px"
                mx="20px">
                <TextCard
                  text="رقم الجوال"
                  value={addData?.mobile}
                  textIsHidden={addData?.hidden_my_number}
                />
                <TextCard
                  text="ملاحظات"
                  value={
                    addData?.comment ? addData?.comment : "لا توجد ملاحظات"
                  }
                />
              </Grid>

              <Flex px="20px" mt="20px">
                <Grid
                  as={TbExclamationMark}
                  placeItems="center"
                  w="25px"
                  h="25px"
                  border="1px solid black"
                  borderRadius="50%"
                  me="10px"
                />
                <Text w={["85%", "70%", "50%", "50%"]}>
                  ينصحك تطبيق ايرباق بتوخى الحذر . يجب عليك مقابلة البائع أو
                  تعيين شخص ما للقاء قبل تحويل أى مبلغ.{" "}
                  <Text
                    as="span"
                    color="text.darkBlue"
                    cursor="pointer"
                    fontWeight="bold"
                    onClick={() => {
                      setShowReportModal(true);
                    }}>
                    الإبلاغ عن الإعلان.
                  </Text>
                </Text>
              </Flex>
            </>
          )}

          {addData?.is_expired && (
            <Flex
              px="20px"
              mt="20px"
              borderRadius="5px"
              bg="#fbe9e7"
              p="20px"
              mx="20px">
              <Grid
                as={TbExclamationMark}
                placeItems="center"
                w="25px"
                h="25px"
                bg="#FFD54F"
                borderRadius="50%"
                me="10px"
              />
              <Box w={["85%", "70%", "50%", "50%"]}>
                <Text fontWeight="bold">الإعلان قديم</Text>
                <Text>
                  لا تقلق، لدينا الآلاف من الإعلانات المميزة الأخرى في المنصة.
                </Text>
              </Box>
            </Flex>
          )}

          <CustomModal
            title="الإبلاغ عن إعلان"
            show={showReportModal}
            setShow={setShowReportModal}>
            <ReportModal id={id} setShowReportModal={setShowReportModal} />
          </CustomModal>
        </Box>
      </Container>
    </Box>
  );
};

export default ShowAd;
