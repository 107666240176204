import axios from "axios";

export const getAuctions = ({ pageParam = 1, queryKey }) => {
  const [_, type, sort] = queryKey;

  return axios.get(
    `lu7ah-api/listings?page=${pageParam}&type=${type}&sort=${sort}&listing_type=auction`
  );
};

export const showAuction = ({ queryKey }) => {
  const [_, auctionId] = queryKey;

  return axios.get(`lu7ah-api/auctions/${auctionId}`);
};

export const createAuction = (data) => {
  return axios.post("lu7ah-api/auctions", data);
};

export const joinAsBidder = (data) => {
  const { auctionId, after_completion_action } = data;

  return axios.post(`lu7ah-api/auctions/${auctionId}/join-as-bidder`, {
    after_completion_action,
  });
};

export const createBid = (data) => {
  const { auctionId, amount } = data;

  return axios.post(`lu7ah-api/auctions/${auctionId}/create-a-bid`, {
    amount,
  });
};

export const cancelAuction = (auctionId) => {
  return axios.delete(`lu7ah-api/auctions/${auctionId}/cancel`);
};

export const acceptHeighestBid = (auctionId) => {
  return axios.post(`lu7ah-api/auctions/${auctionId}/accept-highest-bid`);
};

export const rejectHeighestBid = (auctionId) => {
  return axios.post(`lu7ah-api/auctions/${auctionId}/reject-highest-bid`);
};

export const uploadPaymentProof = ({ auctionId, data }) => {
  return axios.post(
    `lu7ah-api/auctions/${auctionId}/upload-payment-proof`,
    data
  );
};

export const getBids = ({ queryKey }) => {
  const [_, pageNumber] = queryKey;

  return axios.get(
    `lu7ah-api/auctions/my-bids/list?page=${pageNumber}&sort=latest`
  );
};
