import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { TbExclamationMark } from "react-icons/tb";

const StatusTemplate = ({ statusText, statusType, ...props }) => {
  const fail = {
    bg: "#FBE7E3",
    color: "#E4715C",
  };

  const success = {
    bg: "#DCF1E2",
    color: "#0C9E38",
  };

  const pending = {
    bg: "#E8F3FE",
    color: "#278AF4",
  };

  const action = {
    bg: "#FFF4D2",
    color: "#F59629",
  };

  const statusTypes = (statusType) => {
    if (statusType == "pending") {
      return pending;
    } else if (statusType == "success") {
      return success;
    } else if (statusType == "action") {
      return action;
    }
    return fail;
  };

  const status = statusTypes(statusType);

  return (
    <Flex
      gap="10px"
      p="15px"
      borderRadius="10px"
      mb="15px"
      bg="#fbe9e7"
      {...status}
      {...props}>
      <Grid
        as={TbExclamationMark}
        placeItems="center"
        w="25px"
        h="25px"
        border="2px solid "
        borderColor={status.color}
        borderRadius="50%"
      />
      <Text w="85%" fontWeight="bold">
        {statusText}
      </Text>
    </Flex>
  );
};

export default StatusTemplate;
