import StatusTemplate from "./status-template";

const BidderCanceled = ({ ...props }) => {
  return (
    <StatusTemplate
      statusType="fail"
      statusText=" تم إلغاء المزاد من قبل المُعلن. سيتم إرجاع العربون لحسابك خلال 3 أيام "
    />
  );
};

export default BidderCanceled;
