import { Box, Grid, Text } from "@chakra-ui/react";
import { CustomButton } from "components/shared";

const ConfirmationModal = ({ message, action, isLoading, setShow }) => {
  return (
    <Box>
      <Text textAlign="center" mb="25px">
        {message}
      </Text>
      <Grid gridTemplateColumns="repeat(2,1fr)" gap="15px">
        <CustomButton name="تأكيد" onClick={action} loading={isLoading} />
        <CustomButton name="الغاء" onClick={() => setShow(false)} isReject />
      </Grid>
    </Box>
  );
};

export default ConfirmationModal;
