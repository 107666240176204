import StatusTemplate from "./status-template";

const WinnerEndedWithoutRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="تم إلغاء المزاد من قبل منصة لوحة لعدم سداد قيمة اللوحة. الرسوم المسددة غير مستردة      "
      statusType="fail"
    />
  );
};

export default WinnerEndedWithoutRefund;
