import { useState, useEffect, memo } from "react";
import {
  Box,
  Grid,
  Text,
  Flex,
  Button,
  Image,
  useToast,
} from "@chakra-ui/react";
import CountdownTimer from "components/auctions/counter-down";
import {
  Container,
  CustomButton,
  CustomModal,
  Loading,
  Luhah,
  CustomToast,
} from "components/shared";
import { FaPlus, FaMinus, FaCity, FaUsers } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import {
  ConfirmationModal,
  GoToPayModal,
  UploadFile,
  BankAccount,
} from "components/show-auction.js";
import { useQuery, useMutation, isError } from "react-query";
import {
  showAuction,
  cancelAuction,
  acceptHeighestBid,
  rejectHeighestBid,
  createBid,
} from "apis/auctions";
import { useNavigate, useParams } from "react-router-dom";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import {
  BidderCanceled,
  BidderEnded,
  Completed,
  OwnerAwatingPaymentConfirmation,
  OwnerCanceledWithoutRefund,
  OwnerCanceledWithRefund,
  OwnerEndedInvalidWinnerProofOfPaymentWithRefund,
  OwnerEndedWithoutBidsWithRefund,
  OwnerRequestedToAcceptOrRejectHighestBid,
  OwnerWaitingApproval,
  WinnerCanceledWithRefund,
  WinnerEndedWithoutRefund,
  WinnerPaymentProofUploadRequested,
  WinnerPendingPaymentApproval,
  WinnerWatingApproval,
} from "components/show-auction.js/status";

import rajhi from "assets/images/rajhi.svg";

const ShowAuction = () => {
  const [showPayModal, setShowPayModal] = useState(false);
  const [showCreateBid, setShowCreateBid] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showAccept, setShowAccept] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [auctionData, setAuctionData] = useState(false);
  const [auctionInfo, setAuctionInfo] = useState(false);
  const [bids, setBids] = useState([]);
  const [bidsCount, setBidsCount] = useState(0);
  // const [biddersCount, setBiddersCount] = useState(0);
  const [biddingAmount, setBiddingAmount] = useState(0);
  const [count, setCount] = useState(500);
  const user = JSON.parse(localStorage.getItem("lu7ah_user"));
  const accessToken = localStorage.getItem("lu7ah_token");

  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();

  useEffect(() => {
    if (!user) {
      navigate("/signin");
    }
  }, [user]);

  // Initialize Echo for Pusher integration
  useEffect(() => {
    if (!auctionData) return;

    window.Pusher = Pusher;
    const laravelEcho = new Echo({
      broadcaster: "reverb",
      authEndpoint: `${process.env.REACT_APP_API_BASE_URL}/broadcasting/auth`,

      key: process.env.REACT_APP_REVERB_APP_KEY,
      wsHost: process.env.REACT_APP_VITE_REVERB_HOST,
      wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
      wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
      forceTLS: true,
      host: process.env.REACT_APP_VITE_REVERB_HOST,
      enabledTransports: ["wss", "ws"],
      auth: {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "application/json",
        },
      },
    });

    // Fetch auction data and listen for real-time events
    const channel = laravelEcho.private(`auction_bidding.${auctionData?.id}`);

    // Listen for bid creation
    channel.listen(".bid-created", (e) => {
      setBids((prevBids) => [e, ...prevBids]);
      setBidsCount((prevCount) => prevCount + 1);
      setBiddingAmount(e.amount);
      setCount(e?.amount);
    });

    channel.listen(".auction-status-changed", (e) => {
      refetch();
    });

    return () => {
      laravelEcho.disconnect();
    };
  }, [auctionData]);

  const { data, isLoading, refetch } = useQuery(
    ["show Auction", id],
    showAuction,
    {
      onSuccess: (res) => {
        setCount(
          parseInt(
            res?.data?.data?.auction_info?.auction_latest_bidding_amount
          ) + 500
        );
        setAuctionData(res?.data?.data);
        setAuctionInfo(res?.data?.data?.auction_info);
        setBids(res?.data?.data?.auction_info?.top_bids || []);
        setBidsCount(res?.data?.data?.auction_info?.bids || 0);
        // setBiddersCount(res?.data?.data?.auction_info?.bidders || 0);
      },
      onError: (err) => {
        if (err.response.status == 404) {
          navigate("/auctions");
        }
      },
    }
  );

  let isOwner = user?.id == auctionData?.user?.id;

  const { mutate: creatingBid, isLoading: isLoadingCreateBid } = useMutation(
    "create a bid",
    createBid,
    {
      onSuccess: () => {
        setShowCreateBid(false);

        toast({
          render: (props) => (
            <CustomToast
              title={"تمت المزايدة"}
              description={"تمت المزايدة بنجاح"}
              status="success"
              onClose={props.onClose}
            />
          ),
        });
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";
        toast({
          render: (props) => (
            <CustomToast
              title={"دفع المزايدة"}
              description={errorMessage}
              status="error"
              onClose={props.onClose}
            />
          ),
        });
      },
    }
  );

  const { mutate: cancel, isLoading: isLoadingCancel } = useMutation(
    cancelAuction,
    {
      onSuccess: () => {
        setShowCancel(false);
        refetch();
      },
    }
  );

  const { mutate: accept, isLoading: isLoadingAccept } = useMutation(
    acceptHeighestBid,
    {
      onSuccess: () => {
        setShowAccept(false);
        refetch();
      },
    }
  );

  const { mutate: reject, isLoading: isLoadingReject } = useMutation(
    rejectHeighestBid,
    {
      onSuccess: () => {
        setShowReject(false);
        refetch();
      },
    }
  );

  const StatusUi = memo(({ status }) => {
    if (status == "bidder_ended") {
      return <BidderEnded />;
    } else if (status == "bidder_canceled") {
      return <BidderCanceled />;
    } else if (status == "owner_ended_without_bids_with_refund") {
      return <OwnerEndedWithoutBidsWithRefund />;
    } else if (status == "owner_waiting_approval") {
      return <OwnerWaitingApproval bg="bg.pending" />;
    } else if (status == "owner_canceled_with_refund") {
      return <OwnerCanceledWithRefund />;
    } else if (status == "owner_canceled_without_refund") {
      return <OwnerCanceledWithoutRefund />;
    } else if (status == "owner_requested_to_accept_or_reject_highest_bid") {
      return <OwnerRequestedToAcceptOrRejectHighestBid bg="bg.pending" />;
    } else if (status == "owner_awaiting_payment_confirmation") {
      return <OwnerAwatingPaymentConfirmation bg="bg.pending" />;
    } else if (
      status == "owner_ended_invalid_winner_proof_of_payment_with_refund"
    ) {
      return <OwnerEndedInvalidWinnerProofOfPaymentWithRefund />;
    } else if (status == "winner_canceled_with_refund") {
      return <WinnerCanceledWithRefund />;
    } else if (status == "winner_waiting_approval") {
      return <WinnerWatingApproval bg="bg.pending" />;
    } else if (status == "winner_payment_proof_upload_requested") {
      return <WinnerPaymentProofUploadRequested bg="bg.pending" />;
    } else if (status == "winner_pending_payment_approval") {
      return <WinnerPendingPaymentApproval bg="bg.pending" />;
    } else if (status == "winner_ended_without_refund") {
      return <WinnerEndedWithoutRefund />;
    } else if (status == "completed") {
      return <Completed isOwner={isOwner} bg="bg.success" />;
    } else {
      return (
        <>
          <Text
            fontSize="20px"
            textAlign="center"
            color="gray"
            fontWeight="bold">
            الوقت المتبقى حتى نهاية المزاد
          </Text>
          <CountdownTimer
            startTime={auctionData?.auction_info?.auction_start_at}
            endDate={auctionData?.auction_info?.auction_end_at}
            timeStyle={{ fontSize: ["30px", "40px", "40px", "40px"] }}
            typeStyle={{ fontSize: ["16px", "20px", "20px", "20px"] }}
            gap="40px"
          />
        </>
      );
    }
  });

  const CounterAmountDisplay = ({ status }) => {
    if (!isOwner && status == "live") {
      return <Counter />;
    }
  };

  const BidButtonDisplay = ({ status }) => {
    if (!isOwner && status == "live") {
      return (
        <CustomButton
          name={
            auctionData?.auction_info?.user_is_bidder
              ? `المزايدة بقيمة ${count}`
              : "دفع العربون والمزايدة"
          }
          mt="15px"
          onClick={() => {
            if (user) {
              if (auctionData?.auction_info?.user_is_bidder) {
                setShowCreateBid(true);
              } else {
                setShowPayModal(true);
              }
            } else {
              navigate("/signin");
            }
          }}
        />
      );
    }
  };

  const FlexIconText = ({ label, text, icon }) => {
    return (
      <Box p="15px" borderRadius="5px" bg="whitesmoke">
        <Box as={icon} fontSize="22px" color="text.darkBlue" />
        <Text fontSize={["14px", "16px", "18px", "18px"]}>{label}</Text>
        <Text fontWeight="bold" fontSize={["16px", "18px", "22px", "22px"]}>
          {text}
        </Text>
      </Box>
    );
  };

  const Counter = memo(() => {
    const increment = () => setCount(count + 500);
    const decrement = () => {
      if (count > (biddingAmount + 500 || 500)) {
        setCount(count - 500);
      }
    };

    return (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        border="2px solid #137fce"
        borderRadius="5px"
        mb="10px">
        <Button
          color="text.darkBlue"
          bg="none"
          w="10%"
          onClick={increment}
          _hover={{}}
          _active={{}}>
          <Box as={FaPlus} />
        </Button>
        <Text
          w="80%"
          textAlign="center"
          borderInline="1px solid #e0e0e0"
          my="5px"
          fontSize="30px">
          {count}
        </Text>
        <Button
          color="gray"
          bg="none"
          w="10%"
          onClick={decrement}
          _hover={{}}
          _active={{}}>
          <Box as={FaMinus} />
        </Button>
      </Flex>
    );
  });

  if (isLoading && !auctionData) {
    return <Loading />;
  }

  return (
    <Box>
      <Container>
        <Grid
          gridTemplateColumns={[
            "repeat(1,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
            "repeat(2,1fr)",
          ]}
          gap="40px"
          mb={["50px", "0", "", ""]}>
          <Box order={["2", "1", "", ""]}>
            <CounterAmountDisplay status={auctionData?.status} />{" "}
            <Flex
              flexDir={["column", "row", "", ""]}
              justifyContent="space-between"
              alignItems="center"
              bg="whitesmoke"
              p="15px"
              borderRadius="5px"
              mb="15px">
              <Text fontSize="18px"> مبلغ أعلى مزايدة</Text>
              <Text fontSize="30px" color="text.darkBlue" fontWeight="bold">
                {biddingAmount || auctionData?.price} <sub>ر.س</sub>
              </Text>
            </Flex>
            {auctionData?.status == "winner_payment_proof_upload_requested" ? (
              <>
                <BankAccount
                  accountNumber="301000010006080300149"
                  iban="SA2280000301608010300149"
                  imgSrc={rajhi}
                />
                <Box my="15px">
                  <UploadFile auctionId={id} refetch={refetch} />
                </Box>
              </>
            ) : (
              <>
                {user && auctionData?.status == "live" && bids?.length > 0 ? (
                  <Box order={["2", "1", "", ""]}>
                    <Text fontWeight="bold" mb="10px">
                      المزايدات
                    </Text>
                    <Box maxH={["initial", "211px", "", ""]} overflow="auto">
                      {bids?.map((e) => {
                        return (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                            bg="whitesmoke"
                            mb="5px"
                            borderRadius="15px"
                            p="15px">
                            <Flex alignItems="center" gap="10px">
                              <Image
                                src={e?.user_avatar_url}
                                w="50px"
                                h="50px"
                                borderRadius="50%"
                              />
                              <Text fontWeight="bold">{e?.user_name}</Text>
                            </Flex>
                            <Text fontWeight="bold">{e?.amount} ريال </Text>
                          </Flex>
                        );
                      })}
                    </Box>
                  </Box>
                ) : null}
              </>
            )}
            <BidButtonDisplay status={auctionData?.status} />
            {isOwner && auctionData?.status == "live" ? (
              <CustomButton
                name="انهاء المزاد"
                mt="15px"
                onClick={() => setShowCancel(true)}
              />
            ) : null}
            {auctionData?.status ==
            "owner_requested_to_accept_or_reject_highest_bid" ? (
              <Grid gridTemplateColumns="repeat(2,1fr)" gap="15px">
                <CustomButton
                  name="قبول العرض"
                  mt="15px"
                  onClick={() => setShowAccept(true)}
                />

                <CustomButton
                  isReject
                  name="رفض العرض"
                  mt="15px"
                  onClick={() => setShowReject(true)}
                />
              </Grid>
            ) : null}
          </Box>

          <Box order={["1", "2", "", ""]}>
            <StatusUi status={auctionData?.status} />
            <Luhah
              id={auctionData?.id}
              charsAr={auctionData?.chars_ar}
              charsEn={auctionData?.chars_en}
              numbers={auctionData?.numbers}
              type={auctionData?.type}
              me="0"
            />

            <Grid
              gridTemplateColumns="repeat(3,1fr)"
              mt="50px"
              gap={["5px", "10px", "20px", "20px"]}>
              <FlexIconText
                label="المدينة"
                text={auctionData?.city_name}
                icon={FaCity}
              />
              <FlexIconText
                label="عدد المزايدات"
                text={bidsCount}
                icon={FaUsers}
              />
              <FlexIconText
                label="ادنى حد للمزايدة "
                text="500"
                icon={GiMoneyStack}
              />
            </Grid>
          </Box>
        </Grid>
      </Container>
      <CustomModal
        title="الدخول للمزاد"
        titleDir="right"
        show={showPayModal}
        setShow={setShowPayModal}>
        <GoToPayModal
          auctionId={id}
          amount={count}
          topBid={biddingAmount || auctionData?.price}
          auctionEndAt={auctionData?.auction_info?.auction_end_at}
          isPaidFees={auctionData?.user_is_bidder}
        />
      </CustomModal>

      <CustomModal
        title="إضافة مزايدة"
        show={showCreateBid}
        setShow={setShowCreateBid}>
        <ConfirmationModal
          setShow={setShowCreateBid}
          isLoading={isLoadingCreateBid}
          action={() => creatingBid({ auctionId: id, amount: count })}
          message={`هل أنت متأكد من مبلغ المزايدة ${count}`}
        />
      </CustomModal>

      {/* cancel auction */}
      <CustomModal
        title="إلغاء المزاد"
        show={showCancel}
        setShow={setShowCancel}>
        <ConfirmationModal
          setShow={setShowCancel}
          isLoading={isLoadingCancel}
          action={() => cancel(id)}
          message="هل أنت متأكد من إلغاء المزاد؟ لن يتم إسترداد مبلغ العربون في حال الإلغاء من قبل المعلن"
        />
      </CustomModal>

      {/* accept auction */}
      <CustomModal title="قبول العرض" show={showAccept} setShow={setShowAccept}>
        <ConfirmationModal
          setShow={setShowAccept}
          isLoading={isLoadingAccept}
          action={() => accept(id)}
          message="هل أنت متأكد من الموافقة على أعلى سوم للوحة؟"
        />
      </CustomModal>

      {/* reject auction */}
      <CustomModal title="رفض العرض" show={showReject} setShow={setShowReject}>
        <ConfirmationModal
          setShow={setShowReject}
          isLoading={isLoadingReject}
          action={() => reject(id)}
          message="هل أنت متأكد من رفض أعلى سوم للوحة؟"
        />
      </CustomModal>
    </Box>
  );
};

export default ShowAuction;
