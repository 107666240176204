import { Box, Text, useToast } from "@chakra-ui/react";
import { createAuction } from "apis/auctions";
import { CustomButton, CustomToast } from "components/shared";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

const GoToPayModal = ({ values }) => {
  const toast = useToast();
  const navigate = useNavigate();

  const { mutate, isSuccess, isLoading } = useMutation(
    "create-auction",
    createAuction,
    {
      onSuccess: (e) => {
        navigate("/pay", {
          state: {
            urlRequest: e?.data?.redirect_url,
            checkout_details: e?.data?.checkout_details,
          },
        });
      },
      onError: (err) => {
        const errorMessage =
          err?.response?.data?.message || "حدث خطأ ما، برجاء المحاولة لاحقاً";

        toast({
          render: (props) => {
            return (
              <CustomToast
                title={"اضافة مزاد"}
                description={errorMessage}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Text textAlign="center" mb="20px">
        سيتم التوجه لصفحة الدفع. المبلغ المطلوب دفعه الآن هو 345 ريال سعودي
      </Text>
      <CustomButton
        name="تأكيد"
        borderRadius="30px"
        m="auto"
        loading={isLoading}
        onClick={() => {
          mutate({
            type: values.type,
            char1: values.letterOne,
            char2: values.letterTwo,
            char3: values.letterThree,
            number_type: values.numberType,
            num1: values.numbersList[0].toString()[0],
            num2: values.numbersList[1]
              ? values.numbersList[1].toString()[0]
              : "",
            num3: values.numbersList[2]
              ? values.numbersList[2].toString()[0]
              : "",
            num4: values.numbersList[3]
              ? values.numbersList[3].toString()[0]
              : "",
            city: values.city,
            mobile: values.phone,
            price: values.price,
            comment: values.notes,
            cost_transfer: values.cost,
            after_completion_action: `${process.env.REACT_APP_BASE_URL}/user`,
          });
        }}
      />
    </Box>
  );
};

export default GoToPayModal;
