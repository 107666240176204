import StatusTemplate from "./status-template";

const WinnerCanceledWithRefund = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="   تم إلغاء المزاد من قبل المُعلن. سيتم إرجاع العربون لحسابك خلال 3 أيام      "
      statusType="fail"
    />
  );
};

export default WinnerCanceledWithRefund;
