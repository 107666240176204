import axios from "axios";

export const signup = (data) => {
  return axios.post("customer-api/profile/update", { data });
};

export const signin = (data) => {
  return axios.post("auth/firebase", data);
};

export const getUserData = (data) => {
  return axios.get("customer-api/profile");
};

export const contact = (data) => {
  return axios.post("api/contact", data);
};

export const requestOtp = (data) => {
  const { phone } = data;

  return axios.post("auth/request-otp", { mobile: "00966" + phone });
};

export const verifyOtp = (data) => {
  const { otp, mobile, device_token, platform } = data;

  return axios.post("auth/verify-otp", {
    otp,
    mobile: "00966" + mobile,
    device_token,
    platform,
  });
};
