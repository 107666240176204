import StatusTemplate from "./status-template";

const WinnerPendingPaymentApproval = ({ ...props }) => {
  return (
    <StatusTemplate
      statusText="في إنتظار تأكيد وصول المبلغ للحساب للبدء بالإجراءات"
      statusType="pending"
    />
  );
};

export default WinnerPendingPaymentApproval;
