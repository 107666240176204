import { useState } from "react";
import {
  Box,
  Tr,
  Td,
  Text,
  Flex,
  Grid,
  useToast,
  Switch,
} from "@chakra-ui/react";
import {
  Table,
  CustomModal,
  Loading,
  CustomToast,
  CustomButton,
  Luhah,
  FlexContent,
  Pagination,
} from "components/shared";
import RemoveAdd from "../remove-add-modal";
import AddPriceModal from "./add-priceModal";
import { useQuery, useMutation } from "react-query";
import { myListings, updateList } from "apis/lu7ah";
import { Link } from "react-router-dom";
import { ref } from "yup";
import AddRow from "./add-row";
import Card from "./ad-card";
import AuctionCard from "./auction-card";
import moment from "moment";

const Adds = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const { data, isLoading, refetch } = useQuery(
    ["my-listings", pageNumber],
    myListings
  );

  const handlePageClick = async (data) => {
    setPageNumber(data.selected + 1);
    window.scrollTo(0, 30);
  };

  let itemsPerPage = data?.data.meta.per_page;
  let currentPage = data?.data.meta.current_page;
  let itemCount = data?.data.meta.to;
  let totalItems = data?.data.meta.total;
  let numberOfPages = totalItems / itemsPerPage;

  if (isLoading) {
    return (
      <Grid placeItems="center" minH="40vh">
        <Loading />
      </Grid>
    );
  }
  return (
    <Box>
      {/* <Text fontSize="19px" fontWeight="bold" color="#405fa1" mb="15px">
        اعلاناتى
      </Text> */}

      {data?.data?.data.length > 0 ? (
        <>
          <Grid
            gridTemplateColumns={[
              "repeat(1,1fr)",
              "repeat(2,1fr)",
              "repeat(3,1fr)",
              "repeat(3,1fr)",
            ]}
            gap="15px">
            {data?.data?.data?.map((e) => {
              if (e?.is_auction) {
                return (
                  <AuctionCard
                    key={Math.random()}
                    date={moment(e?.created_at).format("yyyy-MM-DD")}
                    serial={e?.serial}
                    id={e?.id}
                    status={e?.status}
                    is_hidden={e?.is_hidden}
                    is_sold={e?.is_sold}
                    numbers={e?.numbers}
                    chars_ar={e?.chars_ar}
                    chars_en={e?.chars_en}
                    type={e?.type}
                  />
                );
              }
              return (
                <>
                  <Card
                    key={Math.random()}
                    date={moment(e?.created_at).format("yyyy-MM-DD")}
                    serial={e?.serial}
                    id={e?.id}
                    is_hidden={e?.is_hidden}
                    is_sold={e?.is_sold}
                    numbers={e?.numbers}
                    chars_ar={e?.chars_ar}
                    chars_en={e?.chars_en}
                    type={e?.type}
                    invoice_url={e?.invoice_url}
                    receipt_url={e?.receipt_url}
                    commission_paid_at={e?.commission_paid_at}
                    refetch={refetch}
                  />
                </>

                // <AddRow
                //   key={Math.random()}
                //   date={e?.created_at}
                //   serial={e?.serial}
                //   id={e?.id}
                //   is_hidden={e?.is_hidden}
                //   is_sold={e?.is_sold}
                //   numbers={e?.numbers}
                //   chars_ar={e?.chars_ar}
                //   commission_paid_at={e?.commission_paid_at}
                //   refetch={refetch}
                // />
              );
            })}
          </Grid>
          {totalItems < itemsPerPage ? null : (
            <Box mt="15px">
              <Pagination
                handlePageClick={handlePageClick}
                numberOfPages={numberOfPages}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                itemCount={itemCount}
                totalItems={totalItems}
              />
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Text textAlign="center"> لا يوجد بيانات لعرضها</Text>
          <CustomButton
            as={Link}
            to="/add-ad"
            name="قم بأضافة إعلان"
            w="50%"
            m="auto"
            display="flex"
            mt="10px"
          />
        </Box>
      )}
    </Box>
  );
};

export default Adds;
